<template>
  <div>

    <b-row class="padingHeader">
      <b-col cols="12" md="5" class="header_state mt-1">
        <span class="" v-if="data.state ==='published'&& data.user === null  && data.applications_count === 0"> <span class="vacant ml-2">•</span><span style="font-size: 1.2rem">Vacant</span></span>
        <span class="" v-if="data.state ==='published'&& data.user === null && data.applications_count !== 0"> <span class="pending ml-2">•</span><span style="font-size: 1.2rem">Pending</span></span>
        <span class="" v-if="data.state ==='published'&& data.user !== null"> <span class="filled ml-2">•</span><span style="font-size: 1.2rem">Filled</span></span>
        <span class="" v-if="data.state ==='draft'&& data.user !== null"> <span class="cancel ml-2">•</span><span style="font-size: 1.2rem">Draft</span></span>
        <span class="" v-if="data.state ==='cancel'"> <span class="cancel ml-2">•</span><span style="font-size: 1.2rem">Cancel</span></span>
        <!--        <span>{{}}</span>-->

        <!--        <span>-->

        <!--        <b-badge size="sm" v-if="data.service.type ==='clinical'" :variant="'light-success'" class="text-capitalize">-->
        <!--         {{data.service.type}}-->
        <!--        </b-badge>-->
        <!--        <b-badge v-else :variant="'light-info'" class="text-capitalize">-->
        <!--          {{data.service.type}}-->
        <!--        </b-badge>-->
        <!--        </span>-->
        <!--        <span class=""  > {{data.State.charAt(0).toUpperCase() + data.State.substring(1)}}</span>-->
      </b-col>
      <!--      <b-col cols="12" md="2" class="header_state">-->
      <!--        <b-button size="sm"  variant="primary" >publish</b-button>-->
      <!--        &lt;!&ndash;        <span class="">.</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span class=""  > {{data.State.charAt(0).toUpperCase() + data.State.substring(1)}}</span>&ndash;&gt;-->
      <!--      </b-col>-->
      <b-col cols="12" md="7">
        <div class="float-right mt-1">
          <!--          <feather-icon-->
          <!--                        class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"-->
          <!--                        icon="CheckIcon"-->
          <!--                        size="16"-->
          <!--                        v-b-tooltip.hover.top="'Complete'"-->

          <!--                        @click="publishEvent(data.Id)"-->
          <!--          />-->
          <feather-icon v-if="data.state === 'draft'"
                        class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
                        icon="UploadIcon"
                        size="16"
                        v-b-tooltip.hover.top="'Publish'"

                        @click="publishEvent(data.Id)"
          />
          <feather-icon v-if="data.State !=='cancelled'"
                        class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
                        icon="EditIcon"
                        size="16"
                        v-b-tooltip.hover.top="'Edit'"
                        @click="editEvent()"
          />
          <feather-icon v-if="data.State !=='cancelled'"
                        class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
                        icon="CopyIcon"
                        size="16"
                        v-b-tooltip.hover.top="'Duplicate'"
                        @click="duplicateEvent()"
          />
          <feather-icon
              v-if="data.State !=='filled'"
              class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
              icon="TrashIcon"
              size="16"
              v-b-tooltip.hover.top="'Delete'"
              @click="deleteShift()"
          />
          <feather-icon v-else
                        class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
                        icon="XSquareIcon"
                        size="16"
                        v-b-tooltip.hover.top="'Cancel'"
                        @click="deleteShiftFilled()"
          />

          <feather-icon
              class="ml-1 cursor-pointer e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
              v-b-tooltip.hover.top="'close'"
              icon="XIcon"
              size="16"
          />
        </div>
      </b-col>

    </b-row>
    <!--    :cell-data="selectedCell"-->
    <!--    <duplicate-single-shift-side-bar ref="DuplicateSingleShiftSideBar"-->
    <!--                                     :cell-data="data"-->
    <!--                                     :is-duplicate-single-shift-sidebar-active.sync="isDuplicateSingleShiftSidebarActive" />-->
  </div>
</template>
<!--intl: new Internationalization(),-->
<script>
import {
  BAlert, BBadge,
  BButton, BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BLink,
  BListGroup,
  BListGroupItem, BModal,
  BRow, BSidebar, VBTooltip
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import shift from '@/apis/modules/shift'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

import duplicateSingleShiftSideBar from '@/views/scheduler/CellEdit/duplicateSideBar'
export default {
  name: 'header',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isDuplicateSingleShiftSidebarActive:false,
      hide:false,
      data: {}
    };
  },
  // watch: {
  //   data:function (){
  //   }
  // },
  components: {
    duplicateSingleShiftSideBar,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BModal,
    BAlert,
    BButtonGroup,
    ToastificationContent,
    BBadge,

  },
  methods: {
    async publishEvent(id){
      this.$swal({
        title: 'Are you sure you want to publish rota?',
        text: "Staff whose shifts have changed will be notified.",
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, publish',
        cancelButtonText: "No, go back",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Published!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          try {
            this.$parent.$parent.$parent.callenderLoadingTrue()
            await shift.publishData({'shift_ids':[id]})
            this.$parent.$parent.$parent.getShiftData()
            // console.log(this.$parent)
           // this.deleteShiftTost('success','top-center', 'Shift was Published!')
           this.showSuccessMessage('Shift was Published!')
          }catch (error){
            this.$parent.$parent.$parent.callenderLoadingFalse()
            this.convertAndNotifyError(error)
          }

        }
      })

      // try {
      //
      //   this.showSuccessMessage('Event Published')
      //   // console.log(this.$parent.$parent.$parent.getShiftData())
      //
      // }catch (error){
      //   this.convertAndNotifyError(error)
      //
      // }

    },
    async editEvent(){
      this.$root.$emit('indexComponentFormEventTemplate') //like this
    },
    // async duplicateEvent(){
    //   this.$root.$emit('indexComponentFormEventTemplateDuplicate') //like this
    // },
    async  duplicateEvent() {
      try {
        this.$root.$emit('indexComponentFormEventTemplateSingleDuplicate')
        // this.isDuplicateSingleShiftSidebarActive = !this.isDuplicateSingleShiftSidebarActive
        // this.$swal({
        //   title: 'Are you sure you want to duplicate shift for next week?',
        //   text: 'Staff whose shifts have changed will be notified.',
        //   icon: 'warning',
        //   showCancelButton: true,
        //   confirmButtonText: 'Yes, Duplicate',
        //   cancelButtonText: 'No, go back',
        //   customClass: {
        //     confirmButton: 'btn btn-primary',
        //     cancelButton: 'btn btn-outline-danger ml-1'
        //   },
        //   buttonsStyling: false
        // }).then(async result => {
        //
        //   if (result.value) {
        //
        //    const  payload= {
        //               end: moment(this.data.EndTime).add('days', 7).utc().format('YYYY-MM-DD HH:mm:ss'),
        //               start: moment(this.data.StartTime).add('days', 7).utc().format('YYYY-MM-DD HH:mm:ss'),
        //               assignee_id:this.data.user === null? null: this.data.user_id,
        //               job_role_id: this.data.group_id,
        //               break_minutes: this.data.break_minutes,
        //               note: this.data.note,
        //               rate: this.data.rate,
        //               is_covid_shift: this.data.is_covid_shift
        //             }
        //     await shift.createShift(payload)
        //
        //
        //
        //   }
        // })

      } catch (error) {
        this.convertAndNotifyError(error)
        this.show = false
      }
    },

    async deleteShift() {


      this.$swal({
        title: 'Are you sure you want to delete this shift?',
        text: "This action cannot be reverted.",
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: "No, go back",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          try {
            await shift.deleteEvent(this.data.Id)
            this.$parent.$parent.$parent.getShiftData()
            // console.log(this.$parent)
            //this.deleteShiftTost('success','top-center', 'Shift was deleted!')
          }catch (error){
            this.convertAndNotifyError(error)
          }

        }
      })
    },
    deleteShiftFilled(){
      this.$swal({
        text: "This action cannot be reverted.'",
        title: 'Are you sure you want to cancel this shift?,',
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel',
        cancelButtonText: "No, go back",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$swal({
            icon: 'success',
            title: 'Canceled',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.deleteShiftTost('success','top-center', 'Shift was canceled!')
        }
      })

    },
    deleteShiftTost(variant, position, title) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: title,
              icon: 'CheckCircleIcon',
              variant,
            },
          },
          {
            position,
          })
    },
  },
  mounted () {

  }
}
</script>

<style >
.vacant{

  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-right: 2px;
  /* identical to box height, or 164% */


  /* Secondary */

  color: #4361EE;
}

.e-popup-header{
  background-color: white !important;
}
.header_state{
  font-size: 18px;
}
.padingHeader {
  padding: 4px!important;
}
.filled{
  /*position: absolute;*/
  /*width: 45px;*/
  /*height: 23px;*/
  /*left: 20px;*/
  /*top: calc(50% - 23px/2 - 121px);*/

  /*font-family: Mulish;*/
  /*font-style: normal;*/
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-right: 2px;
  /* identical to box height, or 164% */


  /* Secondary */

  color: #00C49A;

}
.cancel{

  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-right: 2px;
  /* identical to box height, or 164% */


  /* Secondary */

  color: #B2B2B2;
}

.pending{

  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-right: 2px;
  /* identical to box height, or 164% */


  /* Secondary */

  color: #EFA12C;
}


</style>
