<template>
  <div class="chell">
    <div v-bind:class="{ borderBind: data.state=== 'draft', borderBindBooked: data.state=== 'draft'&& data.user_id>0 }" class="template-wrapper"  v-if="data.state !== 'leave' && data.state !== 'un-availability' && data.state !== 'cancel'">
      <div class="time float-left w-75 " style="width: 50%; padding-top: 0px">
          {{ momentFormat(data.StartTime, 'HH:mm') + ' - ' }} 
          {{ momentFormat(data.EndTime, 'HH:mm') }}
          <div class="text-white font-weight-bold font-weight-700 float-right" style="border-radius: 8px;" v-if="data.break_minutes !==0 && data.break_minutes !== null">
            <span class="black-badge" style="">
              <feather-icon icon="CoffeeIcon" /> 
            </span>
          </div>
          <!-- <span class="ml-1" style="font-size: x-small" v-if="data.break_minutes !==0 && data.break_minutes !== null">({{data.break_minutes}} m)</span> -->
      </div>
      <div class="text-white font-weight-bold font-weight-700 float-right" style="border-radius: 8px;" v-if="data.applications_count !==0 || data.receivedSwapRequests">
        <span v-if="data.applications_count !==0" class="black-badge" style="">
          <feather-icon icon="UserIcon" /> 
          <span>{{data.applications_count}}</span>
        </span>
        <span v-if="data.receivedSwapRequests && data.receivedSwapRequests.length > 0" class="black-badge">
          <span >
            <feather-icon icon="RepeatIcon" />
          </span>
        </span>
      </div>
      <div class="w-50 d-flex" style="" v-if="data.vacant_job_roles">
        <div class="" v-if="data.vacant_job_roles.length===1">{{data.vacant_job_roles[0].job_role.name}}</div>
        <div class="float-left" v-else-if="data.vacant_job_roles.length>1">{{data.vacant_job_roles[0].job_role.name}}...+{{data.vacant_job_roles.length -1}}</div>
      </div>
      <div>
        <div class="float-left w-50 white-template" v-if="data.role_id && data.role_id[0]">
          <div class="white-template-content">
            <div class="" v-if="data.role_id.length===1">{{data.role_id[0].label}}</div>
            <div class="" v-else-if="data.role_id.length>1">{{data.role_id[0].label}}...+{{data.role_id.length -1}}</div>
          </div>
      </div>
      
    </div>

  <!--cancel-->
    <div class="template-wrapper-cancel" v-if="data.state === 'cancel'">
        <div>
          <div class="float-left  w-75 " style="font-size: x-small; width: 50%; padding-top: 3px; padding-left: 3px ">
            <del> {{ momentFormat(data.StartTime, 'HH:mm') + ' - ' }} {{ momentFormat(data.EndTime, 'HH:mm') }}</del>
              <del>
                <span class="ml-1" style="font-size: x-small" v-if="data.break_minutes !==0 && data.break_minutes !== null">({{data.break_minutes}} m)</span>
              </del>
          </div>
          <div v-if="data.applications_count !==0" class="float-right w-25" style="font-size:x-small ; width: 25% ">
            <div class="text-white font-weight-bold font-weight-700" style=" margin-left: 3px; background-color: black;  width: 30px;border-radius: 4px">
              <span class="p-1"> <span>{{ data.applications_count }}</span></span>
            </div>
          </div>
        </div>
        <div>
          <div class="float-left" v-if="data.service.name" style="margin-left: 2px; width: 48%; font-size: x-small; color: rgb(0, 196, 154) ; background: white; margin-top: 3px">
            <del class=" text_service"  style="padding-left: 2px"> {{ data.service.name }}</del>
  <!--          <span class=" text_service" style="padding-left: 2px;">{{ data.service.name }}</span>-->
          </div>
          <div class="float-right pl-1  w-50 " style="margin-top: 3px; font-size:x-small">
            <del class="text"> {{ data.hide_location.name }}</del></div>
        </div>
    </div>
    <div v-if="data.state === 'un-availability'" class="template-wrapper" >
      <span class="  text-dark">User unavailable</span>
      <p  class="  text-dark" >{{momentFormat(data.StartTime, 'HH:mm') +' - '+ momentFormat(data.EndTime,'HH:mm')}}</p>
    </div>
  </div>
  <!--User Leave-->
  <div v-if="data.state=== 'leave'" class="template-wrapper">
    <div class="float-left " >
      <svg fill="none" height="15" viewBox="0 0 12 12" width="15" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 4H9.5C10.0304 4 10.5391 4.21071 10.9142 4.58579C11.2893 4.96086 11.5 5.46957 11.5 6C11.5 6.53043 11.2893 7.03914 10.9142 7.41421C10.5391 7.78929 10.0304 8 9.5 8H9"
            stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M1 4H9V8.5C9 9.03043 8.78929 9.53914 8.41421 9.91421C8.03914 10.2893 7.53043 10.5 7 10.5H3C2.46957 10.5 1.96086 10.2893 1.58579 9.91421C1.21071 9.53914 1 9.03043 1 8.5V4Z"
            stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>

    <p class=" ml-2 text-dark" v-if="data.is_day">Full Day</p>
    <p  v-if="data.state=== 'leave' && !data.is_day" class=" ml-2 text-dark" >{{momentFormat(data.StartTime, 'HH:mm') +' - '+ momentFormat(data.EndTime,'HH:mm')}}</p>
    <p class="text-dark">{{data.reason}}</p>
  </div>

    <!--User Un Availability-->
  <div v-if="data.state === 'un-availability'" class="template-wrapper" >
    <span class="  text-dark">User unavailable</span>
    <p  class="  text-dark" >{{momentFormat(data.StartTime, 'HH:mm') +' - '+ momentFormat(data.EndTime,'HH:mm')}}</p>
  </div>



<!--    <div class="flex" v-if="data.state === 'cancel'">-->
<!--      <div>-->
<!--        <div class="float-left pl-1 w-50" style="font-size: x-small; padding-top: 3px">-->
<!--          <del> {{ momentFormat(data.StartTime, 'HH:mm') + ' - ' }} {{ momentFormat(data.EndTime, 'HH:mm') }}</del>-->
<!--        </div>-->
<!--        <div class="float-right w-50 pl-1 " style="font-size:x-small ;">-->
<!--          <del class="text"> {{ data.hide_location.name }}</del>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <div class="float-left ml-1 " v-if="data.service.name"-->
<!--             style=" font-size: x-small; width: 65%; color: rgb(0, 196, 154) ; background: white;margin-top: 3px">-->
<!--          <del style="padding-left: 2px"> {{ data.service.name }}</del>-->
<!--        </div>-->
<!--        <div v-if="data.applications_count !==0" class="float-right w-25" style="font-size:x-small ; width: 25% ">-->

<!--          <div class="text-white font-weight-bold font-weight-700"-->
<!--               style=" margin-left: 3px; background-color: black;  width: 30px;border-radius: 4px">-->
<!--            <span class="p-1"> <span>{{ data.applications_count }}</span></span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>


</template>

<script>

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import {BAlert, BCol, BRow} from 'bootstrap-vue'

export default {
  name: 'weekTemplate',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  comments: {
    BAlert,
    BRow,
    BCol,

  },
  data() {
    return {
      data: '',
    }
  },

  methods: {
    getTimeString: function (value) {
      return instance.formatDate(value, {skeleton: 'hm'});
    }

  },
  mounted() {
    if (this.data.applications_count !== 0) {
      this.data.color = '#EFA12C'
    }
  }

}
</script>

<style scoped>
.chell {
  width: 100% !important;
}

.template-wrapper {
  padding: 10px;
  height: 100%;
  font-size: 12px;

}

.template-wrapper-cancel {
  padding: 10px;
  height: 100%;
  font-size: 12px;
  color: black;

}

.borderBind{
  border-style: dashed;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  border-color: #4361EE ;
}

.borderBindBooked{
  border-style: dashed;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  border-color: #B2B2B2 ;
}

.black-badge{
  background-color:black; 
  padding: 5px;
}

.white-template{
  margin-right: 4px;  
  color: rgb(0, 196, 154); 
  background: white;  
  width: fit-content;
}

.white-template-content{
  overflow: hidden; 
  text-overflow: ellipsis; 
  background-color: #FFFFFF; 
  border-radius: 3px; 
  padding: 1px ;
  color: #4361EE;
  width: fit-content;
}

</style>
