<template >

  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isVacantMultiJobRoleSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-vacant-multi-job-role-sidebar-active', val)"

  >
    <template #default="{ hide }" >
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Create Shift -  <span v-if="selectedDate">{{momentFormat(selectedDate, ' dddd DD-M-Y')}}</span>
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />

        </div>
        <div class="p-2">
          <validation-observer ref="createShiftForm">
            <b-form @submit.prevent ref="add_shift">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Programme"
                        rules="required"
                    >
                      <label >Programme</label>

                      <v-select
                          v-model="selectedProgramme"
                          label="text"
                          placeholder="Select Programme"
                          :options="programmesOption"
                      />
                      <small  class="text-danger" v-if="errors[0]">The Programme field is required</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Lcation"
                        rules="required"
                    >
                      <label >Location</label>
                      <v-select
                          v-model="selectedLocation"
                          label="text"
                          placeholder="Select Location"
                          :options="locationsOption"
                      />
                      <small  class="text-danger" v-if="errors[0]">The Location field is required</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Role"
                        rules="required"
                    >
                      <label >Role</label>
                      <v-select
                          v-model="selectedJobRole"
                          label="text"
                          placeholder="Select Role"
                          :options="jobRoleOption"
                          multiple
                          :reduce="selectedJobRole => selectedJobRole.id"
                      />
                      <small  class="text-danger" v-if="errors[0]">The Role field is required</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-checkbox
                  v-model="isVacant"
                  value="true"

              >
                Vacant Shift
              </b-form-checkbox>
              <div class="mt-1">
                <b-row class="mt-2">
                  <b-col md="12">
                    <h5 class="mb-2 cursor-pointer " @click="serviceTypeHideFunction(); selectedServiceType = 'clinical'">
                      {{ serviceTypeHide === true ? '-' : '+' }} Service Type (optional)</h5>
                  </b-col>

                  <b-col v-if="serviceTypeHide" md="6">
                    <b-form-checkbox
                        v-model="selectedServiceType"
                        class="float-left"
                        value="clinical"
                    >
                      Clinical
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="selectedServiceType"
                        class="float-right"
                        value="non-clinical"
                    >
                      Non-clinical
                    </b-form-checkbox>


                  </b-col>
                </b-row>
                <b-row v-if="serviceTypeHide" class="">
                  <b-col md="12">
                    <b-form-group>
                      <label></label>
                      <validation-provider
                          #default="{ errors }"
                          name="Service"
                          :rules="selectedServiceType ? 'required' : ''"
                      >
                        <v-select
                            v-if="selectedServiceType"
                            v-model="selectedService"
                            :options="services"
                            label="name"
                            placeholder="Select Service"
                        >
                        <span slot="no-options">
                                Please add services first
                        </span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </b-col>
                </b-row>
                <b-row v-if="serviceTypeHide && selectedServiceType ==='clinical' && services.length !==0" class="">
                  <b-col md="12">


                    <validation-provider
                        #default="{ errors }"
                        name="Shift Type"
                    >
                      <v-select
                          v-if="selectedServiceType"
                          v-model="selectedShiftType"
                          :options="shiftTypes"
                          label="name"
                          placeholder="Select Shift Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>


                  </b-col>
                </b-row>

              </div>
              <b-form-checkbox class="mt-1"
                               v-model="form.is_covid_shift"
                               :value="true"
              >
                COVID-19 Shift
              </b-form-checkbox>

              <div class="mt-1">
                <b-row>
                  <b-col cols="12" md="4">
                    <label>Date</label>

                    <b-form-group>
                      <flat-pickr
                          v-model="selectedDate"
                          :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}}"
                          class="form-control"
                          placeholder="Select a date"
                      />
                    </b-form-group>

                  </b-col>
                  <b-col cols="12" md="4">
                    <label>Start Time</label>
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          name="Start Time"
                          rules="required"
                      >
                        <flat-pickr
                            v-model="selectedStartTime"
                            :config="{ enableTime: true, dateFormat: 'H:i:ss', noCalendar: true , }"
                            class="form-control"
                            placeholder="Start Time"
                        />
                        <small  class="text-danger" v-if="errors[0]">The Start Time field is required</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <label>End Time</label>

                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          name="End Time"
                          rules="required"


                      >
                        <flat-pickr
                            v-model="selectedEndTime"
                            :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                            class="form-control"
                            placeholder="End Time"
                        />
                        <small   class="text-danger" v-if="errors[0]">The End Time field is required</small>
                      </validation-provider>
                    </b-form-group>

                  </b-col>
                </b-row>

              </div>
              <div class="mt-1">
                <h5 class="mb-2 cursor-pointer" @click="breakTimeHideFunction()">
                  {{ breakTimeHide === true ? '-' : '+' }} Add Break (optional)</h5>
                <b-form-group
                    v-if="breakTimeHide"
                    label="Break Minutes"
                    label-for="login-email"
                >
                               <validation-provider
                                   #default="{ errors }"
                                   name="Break Minutes"
                                   rules="numeric"
                               >
                  <b-form-input
                      id="login-email"
                      v-model="form.break_minutes"
                      name="login-email"
                      autocomplete="off"
                      type="number"
                  />
                                 <small class="text-danger">{{ errors[0] }}</small>
                               </validation-provider>
                </b-form-group>
              </div>
              <div class="mt-1">
                <h5 class="mb-2 text-bold">Shift is visible to</h5>
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-checkbox
                        v-model="visible_locums"
                        :value="false"
                    >
                      Salaried Staff
                    </b-form-checkbox>


                  </b-col>
                  <b-col cols="12" md="8">
                    <b-form-checkbox
                        :disabled="!isVacant"
                        v-model="visible_locums"
                        :value="true"

                    >
                      Locums
                    </b-form-checkbox>
                  </b-col>

                </b-row>
              </div>
              <div class="mt-1">
                <h6 v-if="visible_locums == false" class="mb-2">Applicable Hourly Rate for salaried staff</h6>
                <b-row v-if="visible_locums == false"  class="mb-1">
                  <b-col cols="3">
                    <b-form-checkbox
                        v-model="form.salary_staff_rate_type"
                        value="standard"

                    >
                      Standard
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="3">
                    <b-form-checkbox
                        v-model="form.salary_staff_rate_type"
                        value="enhanced"

                    >
                      Enhanced
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <validation-provider v-if="visible_locums == false"
                  #default="{ errors }"
                  class=""
                  name="Salaried hourly rate"
                  rules="required|numeric"
                >
                  <b-input-group
                      size="md"
                      prepend="£"
                  >
                    <b-form-input
                        id="stand"
                        v-model="form.salary_staff_rate"
                        class="float-right"
                        name="login-email"
                        placeholder="Enter hourly rate"
                        type="number"
                    />
                  </b-input-group>
                  <small v-if="form.salary_staff_rate_type === 'standard' && !standard_rate_p_h">Please add standard rate in setting or enter manually</small>
                  <small v-if="form.salary_staff_rate_type === 'enhanced' && !enhanced_rate_p_h">Please add enhanced rate in setting or enter manually</small>
                  <br/>

                  <small  class="text-danger" v-if="errors[0]">Amount should not be negative values</small>
                </validation-provider>

                <div v-if="visible_locums === true ">
                  <h6 class="mb-1">Applicable Hourly Rate locums</h6>

                  <validation-provider
                      #default="{ errors }"
                      name="Locum Hourly Rate"
                      rules="required|numeric"
                  >
                    <b-input-group
                        size="md"
                        prepend="£"
                    >
                      <b-form-input
                          :disabled="!isVacant"
                          id="rate"
                          v-model="form.locum_rate"
                          class="float-right"
                          name="login-email"
                          placeholder="Enter hourly rate"
                          type="number"
                      />
                    </b-input-group>
                    <small v-if="!locum_rate_p_h">Please add locum rate in setting or enter manually</small>
                    <br/>


                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>




              </div>
              <div class="mt-1">
                <label>Notes (optional)</label>
                <b-form-textarea
                    id="textarea-default"
                    v-model="form.note"
                    placeholder="Note"
                    rows="3"
                />
              </div>
              <div class="flex mt-1">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="createShift()"
                >
                  Save
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="white"
                    @click=hide
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormTextarea, VBToggle, VBModal,
  BFormInput,
  BOverlay,
  BForm,
  BInputGroup
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from "vue-ripple-directive";
import shift from "@/apis/modules/shift";
import moment from "moment";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import MomentMixin from '@/mixins/MomentMixin'
import SettingsAPI from '@/apis/modules/settings'

extend('required', {
  message: () => 'This field is required'
})

export default {
  name: 'sideBar',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BForm,
    BInputGroup
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  mixins: [MomentMixin],

  data () {
    return {
      loading:false,
      config:{
        enableTime: false,
        noCalendar: false,
        dateFormat: 'd-m-Y',
        "locale": {
          "firstDayOfWeek": 1
        }
      },
      selectedProgramme:'',
      selectedLocation:'',
      selectedJobRole:[],
      selectedStaff:'',
      timePicker:'',
      programmesOption:[],
      locationsOption:[],
      jobRoleOption:[],
      usersOption:[],
      serviceTypeHide:false,
      breakTimeHide: false,
      selectedServiceType: null,
      services: [],
      selectedService: '',
      selectedShiftType: '',
      shiftTypes: [],
      selectedDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
      visible_locums: false,
      isVacant: false,
      roleList:[],
      enhanced_rate_p_h:'',
      standard_rate_p_h:'',
      locum_rate_p_h:'',
      form: {
        is_visible_locums: '',
        start: '',
        end: '',
        note: '',
        break_minutes: '',
        service_id: '',
        assignee_id: '',
        is_covid_shift: false,
        shift_type_id: '',
        salary_staff_rate_type: 'standard',
        salary_staff_rate: null,
        locum_rate: null

      },
    }
  },
  model: {
    prop: 'isVacantMultiJobRoleSidebarActive',
    event: 'update:is-vacant-multi-job-role-sidebar-active'
  },
  props:{
    isVacantMultiJobRoleSidebarActive: {
      type: Boolean,
      required: true
    },
    selectedMoreData:{
      type: Object,
      required: true,
    }
  },
  computed:{
    selectedProgrammeAndLocation: function (){
      return this.selectedMoreData
    },
    selectedCellData: function (){
      return this.cellData
    }
  },
  watch: {
    async cellData(){
      this.data = await this.cellData
      if (this.data) {
         this.selectedDate = await this.momentFormat(new Date(), 'YYYY-MM-DD')
      }
    },
    selectedJobRole(val){
      this.isVacant = true
      this.getJobRoleList(val.id)
    },
    selectedStaff(){
      this.isVacant = false
    },
    'form.salary_staff_rate_type'(val){
      if(val == 'enhanced'){
        this.form.salary_staff_rate = this.enhanced_rate_p_h
      }else{
        this.form.salary_staff_rate = this.standard_rate_p_h
      }

    },
    async isVacantMultiJobRoleSidebarActive (val) {
      if (!val){
        await this.dataClear()
        return
      }
      this.selectedDate = await this.momentFormat(new Date(), 'YYYY-MM-DD')
      this.selectedStartTime = '08:00:00'
      await this.getProgrammes()
      this.isHaveLeave = false
      this.leaveErrorMessage = ''
    },
    selectedProgramme(val) {
      if (val !== ''){
        this.getLocations(val.id)
      }

    },
    selectedLocation(val){
      this.selectedJobRole= ''
      if (val !== ''){
        this.getUserData(val.id)
      }
    },
    selectedServiceType() {
      if (this.selectedServiceType === 'clinical') {
        this.getServices('clinical')
        this.getShiftTypes()
      } else {
        this.getServices('non-clinical')
      }

    }
  },
  methods:{
    async cellClicked () {
      this.is_cell_clicked = true
      setTimeout(() => {
        this.is_cell_clicked = false
      },1000)
    },
    async getUserData (id) {
      try {
        this.loading = true
        const response = await shift.getUserFormLocation(id)
        const users = response.data.data
        const jobreoleArray = users.map((x)=>({
          id:x.location_job_role.id,
          text:x.location_job_role.name,
        }))

        this.jobRoleOption = _.uniqBy(jobreoleArray,'id')

        this.usersOption = users.map((x)=>({
          id:x.user.id,
          text:x.user.first_name +' '+ x.user.last_name ,
          job_role_id:x.location_job_role.id
        }))
        if (this.selectedCellData.data && this.selectedProgrammeAndLocation.location_id && this.selectedProgrammeAndLocation.location_id ) {
          this.selectedJobRole = await Object(this.jobRoleOption.filter(m => m.id === this.selectedCellData.data.group_id))[0]
          this.selectedStaff = await Object(this.usersOption.filter(m => m.id === this.selectedCellData.data.user_id))[0]
        }

        this.loading = false
      } catch (error) {

        this.convertAndNotifyError(error)
        this.loading = false
      }
    },

    async getServices (filter) {
      try {
        this.loading = true
        const serviceResponse = await shift.getServicesInPCN(filter)

        this.services = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))
        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async getShiftTypes () {
      try {
        this.loading = true
        const serviceResponse = await shift.getShiftTypesInPCN()

        this.shiftTypes = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))
        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async getJobRoleList(id){
      try{
        this.loading = true
        const Response = await SettingsAPI.assignedJobRoleList()
        this.roleList = Response.data.data.map((x) => ({
              id: x.id,
              job_role_id: x.pivot.job_role_id,
              role: x.name,
              standard_rate_p_h: x.pivot.standard_rate_p_h,
              enhanced_rate_p_h: x.pivot.enhanced_rate_p_h,
              locum_rate_p_h: x.pivot.locum_rate_p_h
            })
        )
        const role = []
        this.roleList.forEach(function(x){
          if(x.id == id){
            role.push(x)
          }
        })
        if(role.length == 0){
          this.form.salary_staff_rate = ''
          this.form.locum_rate = ''
          this.enhanced_rate_p_h=''
          this.standard_rate_p_h=''
          this.locum_rate_p_h=''
        }
        this.loading = false
        this.locum_rate_p_h = role[0].locum_rate_p_h
        this.standard_rate_p_h = role[0].standard_rate_p_h
        this.enhanced_rate_p_h = role[0].enhanced_rate_p_h
        this.form.locum_rate = role[0].locum_rate_p_h
        this.form.salary_staff_rate = role[0].standard_rate_p_h

      }catch(error){
        this.convertAndNotifyError(error)
        this.loading = false
      }


    },
    serviceTypeHideFunction () {
      this.serviceTypeHide = !this.serviceTypeHide

    },
    breakTimeHideFunction () {
      this.breakTimeHide = !this.breakTimeHide
    },
    async getProgrammes(){
      try {
        this.loading = true
        const response = await shift.programmes()
        this.programmesOption= response.data.data.map((x) => ({
          id: x.id,
          text:x.name
        }))
        if (this.selectedProgrammeAndLocation.programme_id){
          this.selectedProgramme = Object(this.programmesOption.filter(m => m.id === this.selectedProgrammeAndLocation.programme_id))[0]
        }

        this.loading = false

      }catch (error){
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async getLocations(id){
      try {
        this.loading = true
        const response = await shift.location(id)
        this.locationsOption= response.data.data.map((x) => ({
          id: x.id,
          text:x.name
        }))
        if (this.selectedProgrammeAndLocation.location_id){
          this.selectedLocation = Object(this.locationsOption.filter(m => m.id === this.selectedProgrammeAndLocation.location_id))[0]
        }
        this.loading = false
      }catch (error){
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async createShift () {
      if (await this.$refs.createShiftForm.validate()) {

        try {
          this.loading = true
          this.form.is_visible_locums = this.visible_locums
          this.form.job_role_ids = this.selectedJobRole
          this.form.service_id = this.selectedService.id ? this.selectedService.id : null
          this.form.shift_type_id = this.selectedShiftType.id
          this.form.start = moment(this.selectedDate + ` ` + this.selectedStartTime).utc().format('YYYY-MM-DD HH:mm:ss')
          this.form.end = moment(this.selectedDate + ` ` + this.selectedEndTime).utc().format('YYYY-MM-DD HH:mm:ss')
          this.form.assignee_id = ''
          this.form.p_c_n_programme_id = this.selectedProgramme.id
          this.form.p_c_n_location_id = this.selectedLocation.id
          await shift.createVacantMultiJobRioleShift(this.form)
          this.loading = false
          this.$emit('createShift')

        } catch (error) {
          this.convertAndNotifyError(error)
          this.loading = false
        }
      }
    },
    async dataClear () {
      this.isVacant = false
      this.selectedRole = undefined
      this.selectedHealthcare_professional = null
      this.selectedService = []
      this.form.rate = ''
      this.selectedServiceType = null
      this.selectedLocation =''
      this.selectedProgramme =''
      this.selectedJobRole =''
      this.selectedStaff =''
      this.serviceTypeHide = false
      this.totalHours = 0
      this.selectedStartTime = undefined
      this.selectedEndTime = undefined
      this.selectedDate = ''
      this.form.locum_rate = ''
      this.visible_locums = false
      this.form.salary_staff_rate = ''
      this.enhanced_rate_p_h='',
      this.standard_rate_p_h='',
      this.locum_rate_p_h='',
      this.form.note= ''
      this.$refs.createShiftForm.reset();
    },
  },
  mounted() {
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
