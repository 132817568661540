<template>

  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isEditShiftSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-shift-sidebar-active', val)"

  >
    <template #default="{ hide }">
      <b-overlay :show="loading" rounded="sm">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Edit Shift - {{ momentFormat(selectedDate, 'dddd, DD-MM-YYYY') }}
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />
        </div>
        <div class="p-2">
          <b-tabs>
            <b-tab active title="Shift Details">
              <validation-observer ref="createShiftForm">
                <b-form ref="add_shift" @submit.prevent>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <validation-provider
                            #default="{ errors }"
                            name="Programme"
                            rules="required"
                        >
                          <label>Service Clinic</label>
                          <v-select
                              v-model="selectedProgramme"
                              :options="programmesOption"
                              label="text"
                              placeholder="Select Service Clinic"
                          />
                          <small v-if="errors[0]" class="text-danger">Select Programme</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <validation-provider
                            #default="{ errors }"
                            name="Lcation"
                            rules="required"
                        >
                          <label>Location</label>
                          <v-select
                              v-model="selectedLocation"
                              :options="locationsOption"
                              label="text"
                              placeholder="Select Location"
                          />
                          <small v-if="errors[0]" class="text-danger">Select Location</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="isVacant !== 'true'">
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <validation-provider
                            #default="{ errors }"
                            name="Role"
                            rules="required"
                        >
                          <label>Professions</label>
                          <v-select
                              v-model="selectedJobRole"
                              :options="jobRoleOption"
                              label="text"
                              placeholder="Select Professions"
                          />
                          <small v-if="errors[0]" class="text-danger">Select Professions</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <label>Role (Optional)</label>
                        <v-select
                            v-model="selectedRoles"
                            :options="RoleLists"
                            :reduce="(RoleLists) => RoleLists.value"
                            label="text"
                            multiple
                            placeholder="Select Role"
                        />
                      </b-form-group>
                    </b-col>



<!--                    <b-col cols="12" md="12">-->
<!--                      <b-form-group>-->
<!--                        <label>Healthcare Professional</label>-->
<!--                        <v-select-->
<!--                            v-model="selectedStaff"-->
<!--                            :disabled="isVacant"-->
<!--                            :options="usersOption.filter(x => x.job_role_id === selectedJobRole.id)"-->
<!--                            label="text"-->
<!--                            placeholder=" Select Healthcare Professional"-->
<!--                        />-->
<!--                      </b-form-group>-->
<!--                    </b-col>-->
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <validation-provider
                            #default="{ errors }"
                            name="Roles"
                            rules="required"
                        >
                          <label>Professions</label>
                          <v-select
                              v-model="selectedVacantRole"
                              :options="roleList"
                              :reduce="roleList => roleList.id"
                              label="text"
                              multiple
                              placeholder="Select Professions"
                          />
                          <small v-if="errors[0]" class="text-danger">Select Professions</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <label>Role (Optional)</label>
                        <v-select
                            v-model="selectedRoles"
                            :options="RoleLists"
                            :reduce="(RoleLists) => RoleLists.value"
                            label="text"
                            multiple
                            placeholder="Select Role"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="12">
                      <label>Date</label>

                      <validation-provider
                          #default="{ errors }"
                          name="Date"
                          rules="required"
                      >
                        <b-form-group>
                          <b-form-datepicker
                              id="date"
                              v-model="selectedDate"
                              :date-format-options="{month: 'numeric',day: 'numeric', year: 'numeric' }"
                              :min="min"
                              class="form-control"
                              locale="en-UK"
                              placeholder="Selects"
                              start-weekday="1"
                              trim/>
                          <!-- <flat-pickr
                              v-model="selectedDate"
                              :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d'}"
                              class="form-control"
                              placeholder="Select a date"
                          /> -->
                        </b-form-group>
                        <small v-if="errors[0]" class="text-danger">The Date field is required</small>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group>
                      <label>Start Time</label>
                      
                        <validation-provider
                            #default="{ errors }"
                            name="Start Time"
                            rules="required"
                        >
                        <b-form-group>
                          <b-input-group class="input-group-merge">
                             <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                          <flat-pickr
                              v-model="selectedStartTime"
                              :config="{ enableTime: true, dateFormat: 'H:i:ss', noCalendar: true , }"
                              class="form-control"
                              placeholder="Start"
                          />
                          </b-input-group>
                        </b-form-group>
                          <small v-if="errors[0]" class="text-danger">Select start time</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group>
                      <label>End Time</label>

                      
                        <validation-provider
                            #default="{ errors }"
                            name="End Time"
                            rules="required"

                        >
                        <b-form-group>
                          <b-input-group class="input-group-merge">
                             <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                          <flat-pickr
                              v-model="selectedEndTime"
                              :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                              class="form-control"
                              placeholder="End"
                          />
                          </b-input-group>
                        </b-form-group>
                          <small v-if="errors[0]" class="text-danger">Select end time</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group
                          label="Break Minutes(Optinal)"
                          label-for="login-email"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Break Minutes"
                            rules="numeric"
                        >
                          <b-form-input
                              id="login-email"
                              v-model="formEdit.break_minutes"
                              name="login-email"
                              autocomplete="off"
                              type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>



                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label> Shift Delivery (Optional)</label>
                        <v-select
                            v-model="selectedShiftType"
                            :options="shiftTypes"
                            label="name"
                            placeholder="Select Shift Type"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-checkbox
                          v-model="isVacant"
                          class='mt-1'
                          value="true"

                      >
                        Vacant Shift
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-checkbox v-model="formEdit.is_covid_shift"
                                       :value="true"
                                       class="mt-1"
                      >
                        COVID-19 Shift
                      </b-form-checkbox>
                    </b-col>
                  </b-row>




                  <div class="mt-1">
                    <h6 v-if="visible_locums == false" class="mb-2">Hourly rate (Optional)</h6>
                    <b-row v-if="visible_locums == false" class="mb-1">
                      <b-col cols="3">
                        <b-form-checkbox
                            v-model="formEdit.salary_staff_rate_type"
                            value="standard"

                        >
                          Standard
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="3">
                        <b-form-checkbox
                            v-model="formEdit.salary_staff_rate_type"
                            value="enhanced"

                        >
                          Enhanced
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <validation-provider v-if="visible_locums == false"

                                         #default="{ errors }"
                                         class=""
                                         name="Salaried hourly rate"
                                         rules="numeric"
                    >
                      <b-input-group
                          prepend="£"
                          size="md"
                      >
                        <b-form-input
                            id="stand"
                            v-model="formEdit.salary_staff_rate"
                            class="float-right"
                            name="login-email"
                            placeholder="Enter hourly rate"
                            type="number"
                        />
                      </b-input-group>
                      <!--                  <b-input-group-->
                      <!--                      size="sm"-->
                      <!--                      append=".00"-->
                      <!--                      prepend="$"-->
                      <!--                  >-->
                      <!--                  <b-form-input-->
                      <!--                      id="stand"-->
                      <!--                      v-model="form.salary_staff_rate"-->
                      <!--                      class="float-right mb-1   "-->
                      <!--                      name="login-email"-->
                      <!--                      placeholder="Enter hourly rate"-->

                      <!--                      type="number"-->
                      <!--                  />-->
                      <!--                  </b-input-group>-->
                      <small class="text-danger ">{{ errors[0] }}</small>
                    </validation-provider>

                    <div v-if="visible_locums === true ">
                      <h6 class="mb-1">Applicable Hourly Rate locums</h6>

                      <validation-provider
                          #default="{ errors }"
                          name="Locum hourly rate"
                          rules="required|numeric"
                      >
                        <b-input-group
                            prepend="£"
                            size="md"
                        >
                          <b-form-input
                              id="rate"
                              v-model="formEdit.locum_rate"
                              :disabled="!isVacant"
                              class="float-right"
                              name="login-email"
                              placeholder="Enter hourly rate"
                              type="number"
                          />
                        </b-input-group>

                        <!--                    <b-form-input-->
                        <!--                        id="rate"-->
                        <!--                        v-model="form.locum_rate"-->
                        <!--                        class="float-right "-->
                        <!--                        name="login-email"-->
                        <!--                        placeholder="Enter hourly rate"-->

                        <!--                        type="number"-->
                        <!--                    />-->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>

                    <!--                               <b-row>-->
                    <!--                                   <b-col cols="12" md="6">-->
                    <!--                                     <b-row>-->
                    <!--                                       <b-col md="4"><b-form-checkbox-->
                    <!--                                           v-model="form.hourly_rate"-->
                    <!--                                           value="standard"-->
                    <!--                                       >-->
                    <!--                                         Standard-->
                    <!--                                       </b-form-checkbox>-->
                    <!--                                       </b-col>-->
                    <!--                                       <b-col md="8">-->
                    <!--                                         <b-form-input-->
                    <!--                                             id="rate"-->
                    <!--                                             v-model="form.rate"-->
                    <!--                                             type="number"-->
                    <!--                                             class="float-right"-->

                    <!--                                             name="login-email"-->
                    <!--                                         />-->
                    <!--                                       </b-col>-->
                    <!--                                     </b-row>-->


                    <!--                                   </b-col>-->
                    <!--                                   <b-col cols="12" md="3">-->
                    <!--                                       <b-form-checkbox-->
                    <!--                                               v-model="form.hourly_rate"-->
                    <!--                                               value="enhance"-->
                    <!--                                       >-->
                    <!--                                           Enhanced-->
                    <!--                                       </b-form-checkbox>-->
                    <!--                                   </b-col>-->
                    <!--                                   <b-col cols="12" md="3">-->
                    <!--                                       <b-form-checkbox-->
                    <!--                                               v-model="form.hourly_rate"-->
                    <!--                                               value="locum"-->

                    <!--                                       >-->
                    <!--                                           Locum-->
                    <!--                                       </b-form-checkbox>-->
                    <!--                                   </b-col>-->
                    <!--                               </b-row>-->
                  </div>


                  <div class="mt-1">
                    <b-row class="mt-2">
                      <b-col md="12">
                        <h5 class="mb-2 cursor-pointer "
                            @click="serviceTypeHideFunction(); selectedServiceType = 'clinical'">
                          {{ serviceTypeHide === true ? '-' : '+' }} More options</h5>
                      </b-col>
                    </b-row>
                  <b-row v-if="serviceTypeHide">



                      <b-col  md="6">
                        <h5 class="mb-1"> Activity (Optional)</h5>
                        <b-form-checkbox
                            v-model="selectedServiceType"
                            class="float-left"
                            value="clinical"
                        >
                          Clinical
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="selectedServiceType"
                            class="float-right"
                            value="non-clinical"
                        >
                          Non-clinical
                        </b-form-checkbox>


                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <label></label>
                          <!--                      rules="required"-->
                          <validation-provider
                              #default="{ errors }"
                              :rules="selectedServiceType ? 'required' : ''"
                              name="Service"
                          >
                            <v-select
                                v-if="selectedServiceType"
                                v-model="selectedService"
                                :options="services"
                                label="name"
                                placeholder="Select Service"
                            >
                        <span slot="no-options">
                                Please add services first
                        </span>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                      </b-col>

                    <b-col cols="12" md="12">
                      <label>Notes (optional)</label>
                      <b-form-textarea
                          id="textarea-default"
                          v-model="formEdit.note"
                          placeholder="Note"
                          rows="3"
                      />
                    </b-col>

                    <b-col cols="12" md="12">
                      <h5 class="mb-2 mt-2 text-bold">Shift is visible to</h5>
                      <b-row>
                        <b-col cols="12" md="4">
                          <b-form-checkbox
                              v-model="visible_locums"
                              :value="false"
                          >
                            Salaried Staff
                          </b-form-checkbox>


                        </b-col>
                        <b-col cols="12" md="8">
                          <b-form-checkbox
                              v-model="visible_locums"
                              :disabled="!isVacant"
                              :value="true"

                          >
                            Locums
                          </b-form-checkbox>
                        </b-col>

                      </b-row>
                    </b-col>
                    </b-row>
                    <!--                    <b-row v-if="serviceTypeHide && selectedServiceType ==='clinical' && services.length !==0" class="">-->
                    <!--                     -->
                    <!--                    </b-row>-->

                  </div>



                  <div style="margin-top: 20px">

                    <b-alert
                        show
                        variant="primary"
                    >
                      <div class="alert-body">
                        <b-row>
                          <b-col class="text-bold text-black-50" cols="4">
                            <h5>Total Hours:
                              <span v-if="selectedEndTime&&selectedStartTime">
                            {{ totalHours }}

                          </span>
                              <span v-else>0</span>
                            </h5>
                          </b-col>
                          <b-col class="text-bold text-black-50" cols="8"><h5>Total Shift Cost:

                            <span>
                          <span>£ {{ salariedStaffCost }}</span><span
                                v-if="visible_locums === 'true' ">/ £{{ locumCost }}</span>
                          </span>
                          </h5></b-col>


                        </b-row>
                      </div>
                    </b-alert>


                  </div>


                  <div class="flex mt-1">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="editShift()"
                    >
                      Save
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="white"
                    >
                      Cancel
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-tab>
            <b-tab v-if="showApplication" active title="Applications">
              <b-overlay
                  :show="tableLoading"
                  rounded="sm"
              >

                <b-table
                    ref="tableApplication"
                    :current-page="currentPage"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="getUserApplications"
                    :per-page="pagination.perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"

                    :sort-direction="sortDirection"
                    class="mobile_table_css"

                    hover
                    responsive
                >


                  <template #cell(user)="data">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <b-avatar :src="getUserImage(data.item.user_id, true)" class="pull-up "/>
                      </li>
                      <li class="list-inline-item"> {{ data.item.user }}</li>
                      <b-badge v-if="data.item.is_locum" class="ml-1" style="padding:8px" variant="light-info">
                        <span class="text-dark font-weight-700"> L </span>
                      </b-badge>
                    </ul>


                  </template>
                  <template #cell(action)="data">
                    <div>
                      <b-button
                          v-b-tooltip.hover.top="'Accept'"
                          class="btn-icon bg-white text-primary "
                          size="23"
                          variant="outline-white"
                          @click="acceptUserApplication(data.item.id)"
                      >
                        <feather-icon class="text-primary" icon="CheckSquareIcon" size="18"/>
                      </b-button>


                      <b-button
                          v-b-tooltip.hover.top="'Reject'"
                          class="btn-icon bg-white text-danger ml-1"
                          size="23"
                          variant="outline-white"
                          @click="rejectUserApplication(data.item)"
                      >
                        <feather-icon class="text-danger" icon="XSquareIcon" size="18" variant="outline-danger"/>
                      </b-button>

                    </div>
                  </template>
                </b-table>
                <b-col v-if="noDataTable === 0" class="text-center" cols="12">
                  <span>No data for preview</span>
                </b-col>
              </b-overlay>
            </b-tab>
            <b-tab title="Swap request" v-if="swapableType">
              <div>
                <b-card v-for="swapApplication in swapApplications" :key="swapApplication.id">
                  <b-row>
                    <b-col cols="12" md="8">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <b-avatar
                              :src="getUserImage(swapApplication.shift_user_id)" class="pull-up "/>
                        </li>
                        <li class="list-inline-item ml-1"> {{ swapApplication.shift_user }}</li>
                      </ul>
                    </b-col>
                    <b-col cols="12" md="1">
                      <b-button
                          v-b-tooltip.hover.top="'Accept'"
                          class="btn-icon bg-white text-primary "
                          size="23"

                          variant="outline-white"
                          @click="acceptUserSwapRequest(swapApplication.id)"
                      >
                        <feather-icon class="text-primary" icon="CheckSquareIcon" size="18"/>
                      </b-button>
                    </b-col>
                    <b-col cols="12" md="1">
                      <b-button
                          v-b-tooltip.hover.top="'Reject'"
                          class="btn-icon bg-white text-danger ml-1"
                          size="23"

                          variant="outline-white"
                          @click="rejetUserSwapRequest(swapApplication.id)"
                      >
                        <feather-icon class="text-danger" icon="XSquareIcon" size="18" variant="outline-danger"/>
                      </b-button>
                    </b-col>
                    <b-col cols="12" md="2"></b-col>
                  </b-row>
                </b-card>
              </div>
            </b-tab>
          </b-tabs>

        </div>
      </b-overlay>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormDatepicker,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormTextarea, VBToggle, VBModal, BFormInput, BOverlay, BForm, BInputGroup, BTab,
  BTabs,
  BTable,
  BAvatar,
  BBadge,
  BAlert,
  VBTooltip,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from "vue-ripple-directive";
import shift from "@/apis/modules/shift";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MomentMixin from '@/mixins/MomentMixin'
import moment from "moment";
import SettingsAPI from "@/apis/modules/settings";
import settings from '@/apis/modules/settings'
import dashboard from '@/apis/modules/dashboard'


export default {
  name: 'editSideBar',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BForm,
    BFormDatepicker,
    BInputGroup,
    BTab,
    BTabs,
    BTable,
    BAvatar,
    BBadge,
    BAlert,
    VBTooltip,
    BInputGroupPrepend,
    BInputGroupAppend,

  },
  mixins: [MomentMixin],

  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth())
    minDate.setDate(minDate.getDate())
    return {
      RoleLists:[],
      swapableType:'',
      noDataSwapTable:'',
      swapApplications:[],
      selectedRoles:[],
      selectedVacantRole: [],
      roleList: [],
      showApplication: '',
      totalHours: 0,
      salariedStaffCost: 0,
      locumCost: 0,
      noDataTable: '',
      tableLoading: false,
      min: minDate,
      fields: [
        {
          key: 'user',
          label: 'APPLICANT',
        },
        {
          key: 'action',
          label: 'Actions',
        },


        // {
        //   key: 'action',
        //   label: '',
        // },

      ],
      items: [],
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      config: {
        enableTime: false,
        noCalendar: false,
        dateFormat: 'd-m-Y',
        "locale": {
          "firstDayOfWeek": 1
        }
      },
      dataEvent: {},
      selected: '',
      loading: false,
      selectedProgramme: '',
      selectedLocation: '',
      selectedJobRole: '',
      selectedStaff: '',
      timePicker: '',
      programmesOption: [],
      locationsOption: [],
      jobRoleOption: [],
      usersOption: [],
      serviceTypeHide: false,
      breakTimeHide: false,
      selectedServiceType: null,
      services: [],
      selectedService: '',
      selectedShiftType: '',
      shiftTypes: [],
      selectedDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
      visible_locums: false,
      isVacant: 'false',
      formEdit: {
        is_visible_locums: '',
        start: '',
        end: '',
        note: '',
        break_minutes: '',
        service_id: '',
        assignee_id: '',
        is_covid_shift: false,
        shift_type_id: '',
        salary_staff_rate_type: 'standard',
        salary_staff_rate: null,
        locum_rate: null,
        role_id: undefined

      },
    }

  },
  model: {
    prop: 'isEditShiftSidebarActive',
    event: 'update:is-edit-shift-sidebar-active'
  },
  props: {
    isEditShiftSidebarActive: {
      type: Boolean,
      required: true
    },
    cellData: {
      type: Object,
      required: true
    }
  },
  watch: {
    async 'isEditShiftSidebarActive'(val) {
      if (!val) {
        await this.dataClear()

      } else {

        await this.getAllJobRole()
        await this.filterJobRoles(val)
      }

    },
    cellData() {
      this.dataEvent = this.cellData
      this.setData()
      this.dataEvent.event.user === null ? this.showApplication = true : this.showApplication = false
      this.$refs.tableApplication.refresh()

    },
    selectedProgramme(val) {
      if (val !== '') {
        this.getLocations(val.id)
      }

    },
    async selectedJobRole(val) {
      if (val !== '') {
        await this.getRole(val)
      }
    },

    selectedLocation(val) {
      if (val !== '') {
        this.getUserData(val.id)
      }

    },
    async selectedVacantRole(val) {
      await this.filterJobRoles(val)
    },
    'isVacant'(val) {
      if (val === 'true') {
        this.getAllJobRole()
      }
    },

    selectedServiceType() {
      if (this.selectedServiceType === 'clinical') {
        this.getServices('clinical')
        this.getShiftTypes()
      } else {
        this.getServices('non-clinical')
      }

    },

    selectedStartTime() {
      // this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.formEdit.salary_staff_rate)

    },
    selectedEndTime() {
      // this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.formEdit.salary_staff_rate)


    },
    'formEdit.break_minutes'() {
      // this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.formEdit.salary_staff_rate)
    },
    'formEdit.salary_staff_rate'(newValue) {
      // this.salariedStaffCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime,  newValue)
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, newValue)
    },
    'formEdit.locum_rate'(newValue) {
      // this.locumCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime,  newValue)
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.locumCost = this.totalShiftCost(this.totalHours, newValue)
    },
  },
  methods: {
        //get swap requset
    async getSwapApplications (response) {
      this.swapApplications = response.map((x) => ({
        id: x.id,
        shift: x.shift,
        shift_start_date: this.momentFormat(x.shift.start, 'DD-MM-YYYY'),
        shift_start_time: this.momentFormat(x.shift.start, 'HH:mm'),
        shifte_end_time: this.momentFormat(x.shift.end, 'HH:mm'),
        shift_user: x.shift.assignee.first_name + ' ' + x.shift.assignee.last_name,
        shift_user_id: x.shift.assignee.id
      }))
      this.noDataSwapTable = response.length
    },

    //accept swap application
    async acceptUserSwapRequest (id) {
      try {
        await dashboard.acceptApplicationISwap(id)
        this.showSuccessMessage('Invitation accepted successfully')
        this.$emit('editShift')
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

  //reject swap apploication request
    async rejetUserSwapRequest (id) {
      try {
        const id = item.id
        await dashboard.rejectApplicationSwap(id)
        this.showSuccessMessage('Invitation rejected successfully')
        this.$emit('editShift')
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
       async getRole(val){
      try{
        let allRoles=[]
       const Response= await settings.getRolesWithoutPagination()
        allRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions,
        }))
        let selectedRoleIDS = []
        allRoles.map((x) => {
          x.profession.map((y) => {
            if (val.id === y)
              selectedRoleIDS.push(x.value)
          })
          this.RoleLists = selectedRoleIDS.map((x) => {
            return allRoles.find(e => e.value === x)
          })
        })

      } catch (e) {

      }
    },
    async filterJobRoles(val, id = null) {
      try {
        this.loading = true;
        let allRoles = []
        const Response = await settings.getRolesWithoutPagination()
        allRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions,
        }))

        let selectedRoleIDS = []
        allRoles.map((x) => {
          x.profession.map((y) => {
            val.map((k) => {
              if (k === y)
                selectedRoleIDS.push(x.value)
            })
          })
        })

        this.RoleLists = selectedRoleIDS.map((x) => {
          return allRoles.find(e => e.value === x)
        })

        this.RoleLists = Array.from(new Set(this.RoleLists.map(a => a.value)))
            .map(value => {
              return this.RoleLists.find(a => a.value === value)
            })
        this.loading = false;

      } catch (e) {

      }
    },

    // get All Job Roles
    async getAllJobRole() {
      try {

        const JobRoleResponse = await SettingsAPI.getAllJobRoles()
        this.roleList = JobRoleResponse.data.data.map((x) => ({
          id: x.id,
          text: x.name,
        }))

        if (this.dataEvent.event.vacant_job_roles !== null) {
          this.selectedVacantRole = await this.dataEvent.event.vacant_job_roles.map(x => x.job_role.id)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    // Accept the Application in user

    // async acceptUserApplication(id) {
    //   try {
    //     await shift.acceptApplicationInvitation(id)
    //     this.showSuccessMessage('Invitation accepted successfully')
    //     this.$refs.tableApplication.refresh()
    //     this.$emit('editShift')
    //   } catch (error) {

    //      this.convertAndNotifyError(error)
    //   }
    // },

    async acceptUserApplication(id){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to accept this application?',
        imageUrl: require('@/assets/images/icons/publish.png'),
        imageWidth: 80,
        imageHeight: 80,
        text: '',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
          if(result.value){
            try{
              this.tableLoading = true
              await shift.acceptApplicationInvitation(id)
              this.showSuccessMessage('Application Accepted successfully')
               this.$refs.tableApplication.refresh()
              this.tableLoading = false
              this.$emit('editShift')
            }catch(error){
               this.$refs.tableApplication.refresh()
              this.tableLoading = false
              this.showSuccessMessage('Application Accepted successfully')
            
            }
          }
        })
      this.tableLoading = false
    },



    //Reject the Application in user

    // async rejectUserApplication(item) {
    //   try {
    //     const id = item.id
    //     const user_id = item.user_id
    //     await shift.rejectApplicationInvitation(id, user_id)
    //     this.showSuccessMessage('Invitation rejected successfully')
    //     this.$refs.tableApplication.refresh()
    //     this.$emit('editShift')
    //   } catch (error) {
    //      this.convertAndNotifyError(error)
    //   }
    // },

    async rejectUserApplication(item){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to delete this application?',
        text: '',
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
           if (result.value) {
              try{
                const id = item.id
                const user_id = item.user_id
                this.tableLoading = true
                await shift.rejectApplicationInvitation(id, user_id)
                this.showSuccessMessage('Invitation rejected successfully')
                 this.$refs.tableApplication.refresh()
                this.tableLoading = false
                this.$emit('editShift')
              }catch(error){
                 this.$refs.tableApplication.refresh()
                this.tableLoading = false
                this.showSuccessMessage('Invitation rejected successfully')
            
              }
           }
        })
      this.tableLoading = false
    },

    // get The Applications of staff Apply
    async getUserApplications() {
      try {
        this.tableLoading = true
        const Response = await shift.getApplicationsForVacantShifts(this.dataEvent.event.Id)
        // 10 , this.currentPage,
        this.noDataTable = Response.data.data.length

        let ApplicationArray = Response.data.data.map((x) => ({
          id: x.id,
          user: x.user === null ? 'N/A' : `${x.user.first_name}  ${x.user.last_name}`,
          is_locum: x.is_locum,
          user_id: x.user_id
        }))
        this.tableLoading = false
        return ApplicationArray
      } catch (error) {
        // this.convertAndNotifyError(error)
        console.log(error)
        this.tableLoading = false
      }
    },

    //get User Data function
    async getUserData(id) {
      try {
        this.loading = true
        const response = await shift.getUserFormLocation(id)
        // const jobRoles = response.data.data[0].job_roles[0]
        const users = response.data.data
        const jobreoleArray = users.map((x) => ({
          id: x.location_job_role.id,
          text: x.location_job_role.name,
        }))

        this.jobRoleOption = _.uniqBy(jobreoleArray, 'id')
        this.usersOption = users.map((x) => ({
          id: x.user.id,
          text: x.user.first_name + ' ' + x.user.last_name,
          job_role_id: x.location_job_role.id
        }))

        this.loading = false
      } catch (error) {

        this.convertAndNotifyError(error)
        this.loading = false

      }

    },

    // Get network assigned service
    async getServices(filter) {
      try {
        this.loading = true
        const serviceResponse = await shift.getServicesInPCN(filter)

        this.services = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))
        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async getShiftTypes() {
      try {
        this.loading = true
        const serviceResponse = await shift.getShiftTypesInPCN()

        this.shiftTypes = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))
        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },

    //Service section hide and show
    serviceTypeHideFunction() {
      this.serviceTypeHide = !this.serviceTypeHide

    },
    //Breack section hide and show
    // breakTimeHideFunction() {
    //   this.breakTimeHide = !this.breakTimeHide
    // },

    // get the PCN programmes
    async getProgrammes() {
      try {
        this.loading = true
        const response = await shift.programmes()
        this.programmesOption = response.data.data.map((x) => ({
          id: x.id,
          text: x.name
        }))
        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    // Get the PCN's Locations
    async getLocations(id) {
      try {
        this.loading = true
        const response = await shift.location(id)
        this.locationsOption = response.data.data.map((x) => ({
          id: x.id,
          text: x.name
        }))
        this.loading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },

    // edit shift form submit
    async editShift() {
      if (await this.$refs.createShiftForm.validate()) {

        try {

          if (this.selectedRoles) {
            this.selectedRoles = this.selectedRoles.map((x) => {
              return x.value
            })
          }else{
            this.selectedRoles = null
          }

          this.loading = true
          this.formEdit.role_id = this.selectedRoles;
          this.formEdit.is_visible_locums = this.visible_locums
          this.formEdit.service_id = this.selectedService.id
          this.formEdit.shift_type_id = this.selectedShiftType.id
          this.formEdit.start = moment(this.selectedDate + ` ` + this.selectedStartTime).utc().format('YYYY-MM-DD HH:mm:ss')
          this.formEdit.end = moment(this.selectedDate + ` ` + this.selectedEndTime).utc().format('YYYY-MM-DD HH:mm:ss')
          this.formEdit.p_c_n_programme_id = this.selectedProgramme.id
          this.formEdit.p_c_n_location_id = this.selectedLocation.id
          if (this.isVacant === 'true') {
            this.formEdit.assignee_id = null
            this.formEdit.job_role_id = null
            this.formEdit.job_role_ids = this.selectedVacantRole
          } else {
            this.formEdit.assignee_id = this.selectedStaff.id
            this.formEdit.job_role_id = this.selectedJobRole.id
            this.formEdit.job_role_ids = null
          }
          let updateResponse = await shift.updateShift(this.dataEvent.event.Id, this.formEdit)

          this.loading = false
          if (updateResponse.data.error) {
            this.showErrorMessage('There is an existing shift in this time slot')
          } else {
            this.showSuccessMessage('Shift Updated Successfully')
            this.$emit('editShift')
          }
          // this.showSuccessMessage('Shift Updated Successfully')
          // this.$emit('editShift')
        } catch (error) {
          this.convertAndNotifyError(error)
          this.loading = false
        }
      }
    },
    async dataClear() {
      this.isVacant = 'false'
      this.selectedStaff = null
      this.selectedVacantRole = []
    },
    async setData() {
      this.loading = true
      if (this.dataEvent.event.role_id) {
        this.selectedRoles = this.dataEvent.event.role_id.map((x) => ({
          text: x.label,
          value: x.value,
          profession: x.professions,
        }))
      }
      if (this.dataEvent.event.break_minutes !== null) {
        this.breakTimeHide = true
      }
      if (this.dataEvent.event.receivedSwapRequests) {
        await this.getSwapApplications(this.dataEvent.event.receivedSwapRequests)
        this.swapableType = true
      } else {
        this.swapableType = false
      }
      this.selectedDate = this.momentFormat(this.dataEvent.event.StartTime, 'YYYY-MM-DD')
      this.selectedStartTime = this.momentFormat(this.dataEvent.event.StartTime, 'HH:mm')
      this.selectedEndTime = this.momentFormat(this.dataEvent.event.EndTime, 'HH:mm')
      this.formEdit.break_minutes = this.dataEvent.event.break_minutes
      this.formEdit.rate = this.dataEvent.event.rate
      this.formEdit.note = this.dataEvent.event.note
      this.formEdit.locum_rate = this.dataEvent.event.locum_rate
      this.formEdit.salary_staff_rate = this.dataEvent.event.salary_staff_rate
      this.formEdit.salary_staff_rate_type = this.dataEvent.event.salary_staff_rate_type
      this.visible_locums = !!this.dataEvent.event.is_visible_locums
      this.selectedProgramme = {text: this.dataEvent.event.programme.name, id: this.dataEvent.event.programme.id}
      this.selectedLocation = {text: this.dataEvent.event.location.name, id: this.dataEvent.event.location.id}
      this.isVacant = this.dataEvent.event.job_role === null ? 'true' : 'false'
      if (this.isVacant !== 'true') {
        this.selectedJobRole = {text: this.dataEvent.event.job_role.name, id: this.dataEvent.event.job_role.id}
        this.selectedStaff = this.dataEvent.event.user ? {
          text: this.dataEvent.event.user.first_name + ' ' + this.dataEvent.event.user.last_name,
          id: this.dataEvent.event.user.id
        } : {}
      }
      this.locumCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime, this.formEdit.locum_rate)
      this.salariedStaffCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime, this.formEdit.salary_staff_rate)
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)

      if (this.dataEvent.event.service !== null) {
        this.selectedServiceType = this.dataEvent.event.service.type
        await this.getServices(this.dataEvent.event.service.type)
        this.selectedService = {name: this.dataEvent.event.service.name, id: this.dataEvent.event.service.id}
      } else {

      }
      if (this.dataEvent.event.shift_type !== null) {
        this.selectedShiftTypeForEdit = {
          name: this.dataEvent.event.shift_type.name,
          id: this.dataEvent.event.shift_type.id
        }
      }
      this.loading = false

    }
  },
  mounted() {

  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
