<template >

  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isViewApplicationSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-view-application-sidebar-active', val)"

  >
    <template #default="{ hide }" >
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Shifts Application - Monday, February 22
        </h5>
        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>
      <b-tabs class="p-2">
        <b-tab title="Shift Details">
          <div>
            <b-row>
              <b-col cols="12" md="6">

                <b-form-group>
                  <label >Programme</label>

                  <v-select
                      v-model="selected"
                      label="title"
                      placeholder="Vaccination Programme"
                      :options="[
                            {title:'United Kingdom', value:'hdhdh'},
                            {title:'United States', value:'hdhdh'},
                            {title:'Sri Lanaka', value:'hdhdh'},
                            ]"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group>
                  <label >Location</label>
                  <v-select
                      v-model="selected"
                      label="title"
                      placeholder="Select"
                      :options="[
                            {title:'United Kingdom', value:'hdhdh'},
                            {title:'United States', value:'hdhdh'},
                            {title:'Sri Lanka', value:'hdhdh'},
                            ]"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group>
                  <label >Role</label>
                  <v-select
                      v-model="selected"
                      label="title"
                      placeholder="Health and wellbeing coach"
                      :options="[
                            {title:'Doctor', value:'hdhdh'},
                            {title:'Nurse', value:'hdhdh'},
                            {title:'Physician', value:'hdhdh'},
                            ]"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group>
                  <label >Healthcare Professional</label>
                  <v-select
                      v-model="selected"
                      label="title"
                      placeholder="Bessie Cooper"
                      :options="[
                            {title:'Doctor', value:'hdhdh'},
                            {title:'Nurse', value:'hdhdh'},
                            {title:'Physician', value:'hdhdh'},
                            ]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-checkbox
                v-model="selected"
                value="A"
            >
              Vacant Shift
            </b-form-checkbox>
            <div class="mt-1">
              <b-form-group>
                <label >Shift Type</label>
                <v-select
                    v-model="selected"
                    label="title"
                    placeholder="Select"
                    :options="[
                            {title:'Example 1', value:'hdhdh'},
                            {title:'Example 2', value:'hdhdh'},
                            {title:'Example 3', value:'hdhdh'},
                            ]"
                />
              </b-form-group>
            </div>
            <b-form-checkbox
                v-model="selected"
                value="A"
            >
              COVID-19 Shift
            </b-form-checkbox>

            <div class="mt-1">
              <b-row>
                <b-col cols="12" md="6">
                  <label >Start Time</label>
                  <!--                    <v-select-->
                  <!--                            v-model="selected"-->
                  <!--                            label="title"-->
                  <!--                            placeholder="Select"-->
                  <!--                            :options="[-->
                  <!--                            {title:'Example 1', value:'hdhdh'},-->
                  <!--                            {title:'Example 2', value:'hdhdh'},-->
                  <!--                            {title:'Example 3', value:'hdhdh'},-->
                  <!--                            ]"-->
                  <!--                    />-->
                  <b-form-group>
                    <flat-pickr
                        v-model="timePicker"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <label >End Time</label>
                  <!--                    <v-select-->f
                  <!--                            v-model="selected"-->
                  <!--                            label="title"-->
                  <!--                            placeholder="Select"-->
                  <!--                            :options="[-->
                  <!--                            {title:'Example 1', value:'hdhdh'},-->
                  <!--                            {title:'Example 2', value:'hdhdh'},-->
                  <!--                            {title:'Example 3', value:'hdhdh'},-->
                  <!--                            ]"-->
                  <!--                    />-->
                  <b-form-group>
                    <flat-pickr
                        v-model="timePicker"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mt-1">
              <h5>+ Add Break</h5>
            </div>
            <div class="mt-1">
              <b-row>
                <b-col cols="12" md="4">
                  <b-form-checkbox
                      v-model="selected"
                      value="A"
                  >
                    Salaried Staff
                  </b-form-checkbox>


                </b-col>
                <b-col cols="12" md="8">
                  <b-form-checkbox
                      v-model="selected"
                      value="A"
                      disabled
                  >
                    Salaried Staff and Locums
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
            <div class="mt-1">
              <b-row>
                <b-col cols="12" md="4">
                  <b-form-checkbox
                      v-model="selected"
                      value="A"
                  >
                    Standard
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-checkbox
                      v-model="selected"
                      value="A"
                      disabled
                  >
                    Enhanced
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-checkbox
                      v-model="selected"
                      value="A"
                      disabled
                  >
                    Locum
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
            <div class="mt-1">
              <label >Notes (optional)</label>
              <b-form-textarea
                  id="textarea-default"
                  placeholder="Textarea"
                  rows="3"
              />
            </div>
            <div class="flex mt-1">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="white"
              >
                Cancel
              </b-button>
            </div>

          </div>
<!--          <b-card-text>-->
<!--            Candy canes donut chupa chups candy canes lemon drops oat cake wafer. Cotton candy candy canes marzipan carrot cake. Sesame snaps lemon drops candy marzipan donut brownie tootsie roll. Icing croissant bonbon biscuit gummi bears. Pudding candy canes sugar plum cookie chocolate cake powder croissant.-->
<!--          </b-card-text>-->
<!--          <b-card-text>-->
<!--            Carrot cake tiramisu danish candy cake muffin croissant tart dessert. Tiramisu caramels candy canes chocolate cake sweet roll liquorice icing cupcake. Candy cookie sweet roll bear claw sweet roll.-->
<!--          </b-card-text>-->
        </b-tab>
        <b-tab
            active
            title="Applications"
        >
<!--          <b-card-text>-->
<!--            Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert croissant chocolate jujubes fruitcake. Pie cupcake halvah.-->
<!--          </b-card-text>-->
<!--          <b-card-text>Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate cake gummi bears.</b-card-text>-->

<!--          class="custom_align mobile_table_css"-->
          <b-col cols="12">
            <b-table
                :fields="fields"
                :items="items"
                hover
                class="custom_align mobile_table_css"
                responsive
                @row-clicked="$router.push('')"
            >
              <!-- Name $ Avatar -->

              <template #cell(name)="data">
                <b-row>
                  <b-col md="3">
                    <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')" class="pull-up "/>

                  </b-col>
                  <b-col md="9">
                    <b-row>
                      {{ data.item.name }}

                    </b-row>
                    <b-row>
                      <p class="font-weight-2"> {{data.item.date}}</p>

                    </b-row>
                  </b-col>
                </b-row>
<!--                <ul class="list-inline mb-0">-->
<!--                  <li class="list-inline-item">-->
<!--                    <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')" class="pull-up "/>-->
<!--                  </li>-->
<!--                  <li class="list-inline-item"> {{ data.item.name }}</li>-->
<!--                </ul>-->
              </template>


              <!-- Paid Unpaid Button -->

              <template #cell(action)="data">

                <div class="float-right">
                  <feather-icon v-if="data.State !=='cancelled'"
                                class="ml-1 cursor-pointer"
                                icon="EyeIcon"
                                size="16"
                                @click=""
                  />
                  <feather-icon v-if="data.State !=='cancelled'"
                                class="ml-1 cursor-pointer"
                                icon="CheckSquareIcon"
                                size="16"
                                @click=""
                  />
                  <feather-icon
                      v-if="data.State !=='filled'"
                      class="ml-1 cursor-pointer text-danger"
                      icon="XSquareIcon"
                      size="16"
                      @click=""
                  />
                  <feather-icon v-else
                                class="ml-1 cursor-pointer"
                                icon="XSquareIcon"
                                size="16"
                                @click=""
                  />


                </div>


              </template>
            </b-table>
          </b-col>
        </b-tab>
      </b-tabs>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BAvatar,
  BTable,
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormTextarea, VBToggle, VBModal
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from "vue-ripple-directive";

export default {
  name: "viewApplicationSideBar",
  components: {
    BAvatar,
    BTable,
    BRow,
    BTab,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BTabs,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  data () {
    return {
      data:{},
      selected:'',
      timePicker:'',
      fields: [
        {
          key: 'name',
          label: 'APPLICANT',
          sortable: false
        },
        {
          key: 'action',
          label: 'ACTIONS',
          sortable: false
        },
      ],
      items: [

        {
          'id':'1',
          'name': 'Eleanar Pena',
          'action': 'Clinical pharmacist',
          'date': 'Feb 22, 12:48',

        },
        {
          'id':'2',
          'name': 'Courtney Henry',
          'action': 'Clinical pharmacist',
          'date': 'Feb 24, 16:12',
        },
      ]
    }
  },
  model: {
    prop: 'isViewApplicationSidebarActive',
    event: 'update:is-view-application-sidebar-active'
  },
  props:{
    isViewApplicationSidebarActive: {
      type: Boolean,
      required: true
    },
    cellData:{
      type: Object,
      required: true
    }
  },
  watch:{
    cellData(){
      this.data =this.cellData
    }
  },
  mounted() {
    this.totalRows = this.items.length

  }

}
</script>

<style >
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}

   /*//custom align for locations table to meet the collapse alignments*/
 .custom_align >>> td:nth-last-child(-n+2) {
   text-align: right;
 }

.custom_align >>> th:nth-last-child(-n+2) {
  text-align: right;
}

.custom_align >>> td {
  padding-right: 12px;
}

</style>



