var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isVacantMultiJobRoleSidebarActive,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"change":function (val) { return _vm.$emit('update:is-vacant-multi-job-role-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Create Shift - "),(_vm.selectedDate)?_c('span',[_vm._v(_vm._s(_vm.momentFormat(_vm.selectedDate, ' dddd DD-M-Y')))]):_vm._e()]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"createShiftForm"},[_c('b-form',{ref:"add_shift",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Programme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Programme")]),_c('v-select',{attrs:{"label":"text","placeholder":"Select Programme","options":_vm.programmesOption},model:{value:(_vm.selectedProgramme),callback:function ($$v) {_vm.selectedProgramme=$$v},expression:"selectedProgramme"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The Programme field is required")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Lcation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Location")]),_c('v-select',{attrs:{"label":"text","placeholder":"Select Location","options":_vm.locationsOption},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The Location field is required")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Role")]),_c('v-select',{attrs:{"label":"text","placeholder":"Select Role","options":_vm.jobRoleOption,"multiple":"","reduce":function (selectedJobRole) { return selectedJobRole.id; }},model:{value:(_vm.selectedJobRole),callback:function ($$v) {_vm.selectedJobRole=$$v},expression:"selectedJobRole"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The Role field is required")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"true"},model:{value:(_vm.isVacant),callback:function ($$v) {_vm.isVacant=$$v},expression:"isVacant"}},[_vm._v(" Vacant Shift ")]),_c('div',{staticClass:"mt-1"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"mb-2 cursor-pointer ",on:{"click":function($event){_vm.serviceTypeHideFunction(); _vm.selectedServiceType = 'clinical'}}},[_vm._v(" "+_vm._s(_vm.serviceTypeHide === true ? '-' : '+')+" Service Type (optional)")])]),(_vm.serviceTypeHide)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-checkbox',{staticClass:"float-left",attrs:{"value":"clinical"},model:{value:(_vm.selectedServiceType),callback:function ($$v) {_vm.selectedServiceType=$$v},expression:"selectedServiceType"}},[_vm._v(" Clinical ")]),_c('b-form-checkbox',{staticClass:"float-right",attrs:{"value":"non-clinical"},model:{value:(_vm.selectedServiceType),callback:function ($$v) {_vm.selectedServiceType=$$v},expression:"selectedServiceType"}},[_vm._v(" Non-clinical ")])],1):_vm._e()],1),(_vm.serviceTypeHide)?_c('b-row',{},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label'),_c('validation-provider',{attrs:{"name":"Service","rules":_vm.selectedServiceType ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.selectedServiceType)?_c('v-select',{attrs:{"options":_vm.services,"label":"name","placeholder":"Select Service"},model:{value:(_vm.selectedService),callback:function ($$v) {_vm.selectedService=$$v},expression:"selectedService"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Please add services first ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.serviceTypeHide && _vm.selectedServiceType ==='clinical' && _vm.services.length !==0)?_c('b-row',{},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Shift Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.selectedServiceType)?_c('v-select',{attrs:{"options":_vm.shiftTypes,"label":"name","placeholder":"Select Shift Type"},model:{value:(_vm.selectedShiftType),callback:function ($$v) {_vm.selectedShiftType=$$v},expression:"selectedShiftType"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"value":true},model:{value:(_vm.form.is_covid_shift),callback:function ($$v) {_vm.$set(_vm.form, "is_covid_shift", $$v)},expression:"form.is_covid_shift"}},[_vm._v(" COVID-19 Shift ")]),_c('div',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Date")]),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}},"placeholder":"Select a date"},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Start Time")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Start Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'H:i:ss', noCalendar: true , },"placeholder":"Start Time"},model:{value:(_vm.selectedStartTime),callback:function ($$v) {_vm.selectedStartTime=$$v},expression:"selectedStartTime"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The Start Time field is required")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("End Time")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',},"placeholder":"End Time"},model:{value:(_vm.selectedEndTime),callback:function ($$v) {_vm.selectedEndTime=$$v},expression:"selectedEndTime"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The End Time field is required")]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('div',{staticClass:"mt-1"},[_c('h5',{staticClass:"mb-2 cursor-pointer",on:{"click":function($event){return _vm.breakTimeHideFunction()}}},[_vm._v(" "+_vm._s(_vm.breakTimeHide === true ? '-' : '+')+" Add Break (optional)")]),(_vm.breakTimeHide)?_c('b-form-group',{attrs:{"label":"Break Minutes","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Break Minutes","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","name":"login-email","autocomplete":"off","type":"number"},model:{value:(_vm.form.break_minutes),callback:function ($$v) {_vm.$set(_vm.form, "break_minutes", $$v)},expression:"form.break_minutes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"mt-1"},[_c('h5',{staticClass:"mb-2 text-bold"},[_vm._v("Shift is visible to")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-checkbox',{attrs:{"value":false},model:{value:(_vm.visible_locums),callback:function ($$v) {_vm.visible_locums=$$v},expression:"visible_locums"}},[_vm._v(" Salaried Staff ")])],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.isVacant,"value":true},model:{value:(_vm.visible_locums),callback:function ($$v) {_vm.visible_locums=$$v},expression:"visible_locums"}},[_vm._v(" Locums ")])],1)],1)],1),_c('div',{staticClass:"mt-1"},[(_vm.visible_locums == false)?_c('h6',{staticClass:"mb-2"},[_vm._v("Applicable Hourly Rate for salaried staff")]):_vm._e(),(_vm.visible_locums == false)?_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-checkbox',{attrs:{"value":"standard"},model:{value:(_vm.form.salary_staff_rate_type),callback:function ($$v) {_vm.$set(_vm.form, "salary_staff_rate_type", $$v)},expression:"form.salary_staff_rate_type"}},[_vm._v(" Standard ")])],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-checkbox',{attrs:{"value":"enhanced"},model:{value:(_vm.form.salary_staff_rate_type),callback:function ($$v) {_vm.$set(_vm.form, "salary_staff_rate_type", $$v)},expression:"form.salary_staff_rate_type"}},[_vm._v(" Enhanced ")])],1)],1):_vm._e(),(_vm.visible_locums == false)?_c('validation-provider',{attrs:{"name":"Salaried hourly rate","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"md","prepend":"£"}},[_c('b-form-input',{staticClass:"float-right",attrs:{"id":"stand","name":"login-email","placeholder":"Enter hourly rate","type":"number"},model:{value:(_vm.form.salary_staff_rate),callback:function ($$v) {_vm.$set(_vm.form, "salary_staff_rate", $$v)},expression:"form.salary_staff_rate"}})],1),(_vm.form.salary_staff_rate_type === 'standard' && !_vm.standard_rate_p_h)?_c('small',[_vm._v("Please add standard rate in setting or enter manually")]):_vm._e(),(_vm.form.salary_staff_rate_type === 'enhanced' && !_vm.enhanced_rate_p_h)?_c('small',[_vm._v("Please add enhanced rate in setting or enter manually")]):_vm._e(),_c('br'),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Amount should not be negative values")]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.visible_locums === true )?_c('div',[_c('h6',{staticClass:"mb-1"},[_vm._v("Applicable Hourly Rate locums")]),_c('validation-provider',{attrs:{"name":"Locum Hourly Rate","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"md","prepend":"£"}},[_c('b-form-input',{staticClass:"float-right",attrs:{"disabled":!_vm.isVacant,"id":"rate","name":"login-email","placeholder":"Enter hourly rate","type":"number"},model:{value:(_vm.form.locum_rate),callback:function ($$v) {_vm.$set(_vm.form, "locum_rate", $$v)},expression:"form.locum_rate"}})],1),(!_vm.locum_rate_p_h)?_c('small',[_vm._v("Please add locum rate in setting or enter manually")]):_vm._e(),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"mt-1"},[_c('label',[_vm._v("Notes (optional)")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Note","rows":"3"},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1),_c('div',{staticClass:"flex mt-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.createShift()}}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"white"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }