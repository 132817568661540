var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isDuplicateShiftSidebarActive,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"change":function (val) { return _vm.$emit('update:is-duplicate-shift-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.data!== null)?_c('h5',{staticClass:"mb-0"},[_vm._v(" Duplicate Week ")]):_vm._e(),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"duplicateShift"},[_c('b-form',{ref:"duplicate_shift",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('p',[_vm._v("All shifts will be duplicated as drafts. To make them visible to staff, you will need to publish rota. ")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('p',{staticClass:"font-weight-bold font-weight-700"},[_vm._v("Copy from:")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Copy Programme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Programme")]),_c('v-select',{attrs:{"label":"text","placeholder":"Select Programme","options":_vm.programmesOption},model:{value:(_vm.selectedCopyProgramme),callback:function ($$v) {_vm.selectedCopyProgramme=$$v},expression:"selectedCopyProgramme"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Copy Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Location")]),_c('v-select',{attrs:{"label":"text","placeholder":"Select Locations","options":_vm.copyLocationOption},model:{value:(_vm.selectedCopyLocation),callback:function ($$v) {_vm.selectedCopyLocation=$$v},expression:"selectedCopyLocation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',[_vm._v("Week")]),_c('validation-provider',{attrs:{"name":"Copy from","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.copyFromOptions,"label":"text","placeholder":"Select week","value":"id"},model:{value:(_vm.selectedCopyFrom),callback:function ($$v) {_vm.selectedCopyFrom=$$v},expression:"selectedCopyFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row'),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold font-weight-700"},[_vm._v("Paste to:")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Programme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("programme")]),_c('v-select',{attrs:{"label":"text","placeholder":"Select Programme","options":_vm.programmesOption,"disabled":""},model:{value:(_vm.selectedCopyProgramme),callback:function ($$v) {_vm.selectedCopyProgramme=$$v},expression:"selectedCopyProgramme"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Location")]),_c('v-select',{attrs:{"label":"text","placeholder":"Select Location","options":_vm.pasteLocationsOption,"disabled":""},model:{value:(_vm.selectedCopyLocation),callback:function ($$v) {_vm.selectedCopyLocation=$$v},expression:"selectedCopyLocation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('p',{},[_vm._v("Week(s):")]),_c('validation-provider',{attrs:{"name":"Paste to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.copyToOptions,"label":"text","multiple":"","placeholder":"Select week","value":"id"},model:{value:(_vm.selectedPastTo),callback:function ($$v) {_vm.selectedPastTo=$$v},expression:"selectedPastTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),(_vm.selectedCopyFrom && _vm.selectedShiftCount)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{staticClass:"w-100",attrs:{"show":"","variant":"success"}},[_c('div',{staticClass:"alert-body text-black-50"},[_vm._v(" Total shift drafts to be created: "+_vm._s(_vm.selectedShiftCount)+" ")])])],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"flex"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"name":"check-button","switch":""},model:{value:(_vm.shift_with_allocated_staff),callback:function ($$v) {_vm.shift_with_allocated_staff=$$v},expression:"shift_with_allocated_staff"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{staticStyle:{"color":"white"},attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{staticStyle:{"color":"rgba(8, 4, 51, 0.5)"},attrs:{"icon":"XIcon"}})],1)]),_c('p',[_vm._v("Copy vacant shifts only")])],1)])],1),_c('div',{staticClass:"flex mt-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.duplicateFormSubmit()}}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2",attrs:{"variant":"white"},on:{"click":function($event){_vm.isDuplicateShiftSidebarActive = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }