<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewShiftSidebarActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="(val) => $emit('update:is-add-new-shift-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" rounded="sm">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Vacant Shift -
            <span v-if="selectedDate">{{
              momentFormat(selectedDate, " dddd DD-M-Y")
            }}</span>
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="cancel(); hide"
          />
        </div>
        <div class="p-2">
          <validation-observer ref="createShiftForm">
            <b-form ref="add_shift" @submit.prevent>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Programme"
                      rules="required"
                    >
                      <label>Service Clinic</label>

                      <v-select
                        v-model="selectedProgramme"
                        :options="programmesOption"
                        label="text"
                        placeholder="Select Service Clinic"
                      />
                      <small v-if="errors[0]" class="text-danger"
                        >The Programme field is required</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Location"
                      rules="required"
                    >
                      <label>Location</label>
                      <v-select
                        v-model="selectedLocation"
                        :options="locationsOption"
                        label="text"
                        placeholder="Select Location"
                      />
                      <small v-if="errors[0]" class="text-danger"
                        >The Location field is required</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--              <div class="mt-1">-->
              <!--                <b-row class="mt-2">-->
              <!--                  <b-col md="12">-->
              <!--                    <h5 class="mb-2 cursor-pointer">-->
              <!--                      Shift based on:</h5>-->
              <!--                  </b-col>-->

              <!--                  <b-col  md="6">-->
              <!--                    <b-form-checkbox-->
              <!--                        v-model="selectedShiftBase"-->
              <!--                        class="float-left"-->
              <!--                        value="competency"-->
              <!--                    >-->
              <!--                      Competency-->
              <!--                    </b-form-checkbox>-->
              <!--                    <b-form-checkbox-->
              <!--                        v-model="selectedShiftBase"-->
              <!--                        class="float-right"-->
              <!--                        value="profession"-->
              <!--                    >-->
              <!--                      Profession-->
              <!--                    </b-form-checkbox>-->

              <!--                  </b-col>-->
              <!--                </b-row>-->
              <!--              </div>-->

              <!--              <b-form-checkbox-->
              <!--                  v-model="isVacant"-->
              <!--                  value="true"-->

              <!--              >-->
              <!--                Vacant Shift-->
              <!--              </b-form-checkbox>-->
              <!--              scoral bar -->

              <div class="mt-1">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Role"
                        rules="required"
                      >
                        <label>Professions</label>
                        <v-select
                          v-model="selectedJobRole"
                          :options="jobRoleOption"
                          :reduce="(jobRoleOption) => jobRoleOption.id"
                          label="text"
                          multiple
                          placeholder="Select Profession"
                        >
                          <span slot="no-options">
                            Please Select Location First
                          </span>
                        </v-select>
                        <small v-if="errors[0]" class="text-danger"
                          >The Role field is required</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label>Role (Optional)</label>
                      <validation-provider #default="{ errors }" name="Role">
                        <v-select
                          v-model="selecetedRoles"
                          :options="RoleLists"
                          :reduce="(RoleLists) => RoleLists.value"
                          label="text"
                          multiple
                          placeholder="Select Role"
                        >
                          <span slot="no-options">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="$router.push(`/settings?active_tab=2`)"
                              size="sm"
                            >
                              Add
                            </b-button>
                          </span>
                        </v-select>

                        <small v-if="errors[0]" class="text-danger"
                          >The Professions field is required</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--                  <b-col cols="12" md="12">-->
                  <!--                    <label>Date</label>-->
                  <!--                    <b-form-group label-for="start_date">-->
                  <!--                      <validation-provider-->
                  <!--                        #default="{ errors }"-->
                  <!--                        name="Start Date"-->
                  <!--                        rules=""-->
                  <!--                      >-->
                  <!--                        <b-form-datepicker-->
                  <!--                          id="start_date"-->
                  <!--                          v-model="selectedDate"-->
                  <!--                          :date-format-options="{-->
                  <!--                            month: 'numeric',-->
                  <!--                            day: 'numeric',-->
                  <!--                            year: 'numeric',-->
                  <!--                          }"-->
                  <!--                          :min="min"-->
                  <!--                          locale="en-UK"-->
                  <!--                          class="form-control"-->
                  <!--                          placeholder="Select"-->
                  <!--                          start-weekday="1"-->
                  <!--                          trim-->
                  <!--                        />-->

                  <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                  <!--                      </validation-provider>-->
                  <!--                    </b-form-group>-->
                  <!--                  </b-col>-->

                  <!-- <b-col cols="12" md="6">
                    <label>Date</label>

                    <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                    >
                      <b-form-group>
                        <validation-provider
                            #default="{ errors }"
                            name="Date"
                            rules="required"
                        >
                          <flat-pickr
                              v-model="selectedDate"
                              :config="{ enableTime: false, noCalendar: false, dateFormat: 'd-m-Y', locale: {firstDayOfWeek: 1},}"
                              class="form-control"
                              placeholder="Select a date"
                          />
                          <small  class="text-danger" v-if="errors[0]">The Date field is required</small>
                        </validation-provider>
                      </b-form-group>
                      <small class="text-danger" v-if="errors[0]">The Date field is required</small>
                    </validation-provider>
                  </b-col> -->

                  <!--                <b-col cols="12" md="6">-->
                  <!--                  <b-form-group>-->
                  <!--                    <label >Healthcare Professional</label>-->
                  <!--                    <v-select-->
                  <!--                        v-model="selectedStaff"-->
                  <!--                        label="text"-->
                  <!--                        placeholder=" Select Healthcare Professional"-->
                  <!--                        :options="usersOption.filter(x => x.job_role_id === selectedJobRole.id)"-->
                  <!--                        :disabled="isVacant"-->
                  <!--                    />-->
                  <!--                  </b-form-group>-->
                  <!--                </b-col>-->
                </b-row>
              </div>

              <h5 v-if="ShiftTemplates.length !== 0" class="mt-2">
                Shift templates (select applicable shift)
              </h5>

              <div class="container-fluid">
                <div class="scrolling-wrapper row flex-row flex-nowrap pt-2">
                  <div
                    v-for="template in ShiftTemplates"
                    :key="template.id"
                    :index="template.id"
                    class="col-5"
                    @click="shiftTemplateSet(template)"
                  >
                    <div
                      class="card card-block"
                      v-bind:class="
                        template.isSelect === true
                          ? cardBckground2
                          : cardBckground1
                      "
                      @click="changeColor(template)"
                    >
                      <b-row>
                        <b-col cols="6">
                          <span
                            style="
                              padding-left: 7px;
                              color: white;
                              font-size: x-small;
                            "
                          >
                            {{ template.start_time }} - {{ template.end_time }}
                          </span>
                        </b-col>
                        <b-col cols="6">
                          <div
                            class=""
                            style="
                              background-color: white;
                              text-overflow: ellipsis;
                              margin-right: 7px;
                            "
                          >
                            <span
                              style="
                                padding: 3px;
                                color: #353434;
                                font-size: x-small;
                              "
                              >{{
                                template.service.name.substring(0, 12)
                              }}</span
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>

              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group>
                    <label>Start Time</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Start Time"
                      rules="required"
                    >
                      <b-form-group>
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="ClockIcon" />
                          </b-input-group-prepend>
                          <flat-pickr
                            v-model="selectedStartTime"
                            :config="{
                              enableTime: true,
                              dateFormat: 'H:i:ss',
                              noCalendar: true,
                            }"
                            class="form-control"
                            placeholder="Start Time"
                          />
                        </b-input-group>
                        <div v-if="isEndDate">
                        <small v-if="errors[0]" class="text-danger"
                          >The Start Time field is required</small
                        >
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group>
                    <label>End Time</label>
                    <validation-provider
                      #default="{ errors }"
                      name="End Time"
                      rules="required"
                    >
                      <b-form-group>
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="ClockIcon" />
                          </b-input-group-prepend>
                          <flat-pickr
                            v-model="selectedEndTime"
                            :config="{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'H:i:ss',
                            }"
                            class="form-control"
                            placeholder="End Time"
                          />
                        </b-input-group>
                      </b-form-group>
                      <div v-if="isEndDate">
                      <small v-if="errors[0]" class="text-danger"
                        >The End Time field is required</small
                      >
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Break Minutes (Optional)"
                    label-for="login-break_minutes"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Break Minutes"
                      rules="numeric"
                    >
                      <b-form-input
                        id="login-break_minutes"
                        v-model="form.break_minutes"
                        name="login-break_minutes"
                        autocomplete="off"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!--                v-if="&ndash;&gt;-->
                <!--                      selectedServiceType === 'clinical' &&-->
                <!--                        services.length !== 0-->
                <!--                    "-->
                <!--                  class=""-->
                <b-col md="12">
                  <b-form-group
                    label="Shift delivery (Optional)"
                    label-for="Shift-delivery"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Shift Type"
                    >
                      <v-select
                        v-if="selectedServiceType"
                        v-model="selectedShiftType"
                        :options="shiftTypes"
                        label="name"
                        placeholder="Select Shift delivery"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="mt-1">
                <h6 v-if="visible_locums == false" class="mb-2">
                  Applicable Hourly Rate for salaried staff
                </h6>
                <b-row v-if="visible_locums == false" class="mb-1">
                  <b-col cols="3">
                    <b-form-checkbox
                      v-model="form.salary_staff_rate_type"
                      value="standard"
                    >
                      Standard
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="3">
                    <b-form-checkbox
                      v-model="form.salary_staff_rate_type"
                      value="enhanced"
                    >
                      Enhanced
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <validation-provider
                  v-if="
                    visible_locums == false &&
                    (form.salary_staff_rate_type === 'standard' ||
                      form.salary_staff_rate_type === 'enhanced')
                  "
                  #default="{ errors }"
                  class=""
                  name="Salaried hourly rate"
                  rules="required|numeric"
                >
                  <b-input-group prepend="£" size="md">
                    <b-form-input
                      id="stand"
                      v-model="form.salary_staff_rate"
                      class="float-right"
                      name="login-email"
                      placeholder="Enter hourly rate"
                      type="number"
                    />
                  </b-input-group>
                  <small
                    v-if="
                      form.salary_staff_rate_type === 'standard' &&
                      !standard_rate_p_h
                    "
                    >Please add standard rate in setting or enter
                    manually</small
                  >
                  <small
                    v-if="
                      form.salary_staff_rate_type === 'enhanced' &&
                      !enhanced_rate_p_h
                    "
                    >Please add enhanced rate in setting or enter
                    manually</small
                  >
                  <br />
                  <!--                  <b-input-group-->
                  <!--                      size="sm"-->
                  <!--                      append=".00"-->
                  <!--                      prepend="$"-->
                  <!--                  >-->
                  <!--                  <b-form-input-->
                  <!--                      id="stand"-->
                  <!--                      v-model="form.salary_staff_rate"-->
                  <!--                      class="float-right mb-1   "-->
                  <!--                      name="login-email"-->
                  <!--                      placeholder="Enter hourly rate"-->

                  <!--                      type="number"-->
                  <!--                  />-->
                  <!--                  </b-input-group>-->
                  <small v-if="errors[0]" class="text-danger"
                    >Amount should not be negative values</small
                  >
                </validation-provider>

                <div v-if="visible_locums === true">
                  <h6 class="mb-1">Applicable Hourly Rate locums</h6>

                  <validation-provider
                    #default="{ errors }"
                    name="Locum Hourly Rate"
                    rules="required|numeric"
                  >
                    <b-input-group prepend="£" size="md">
                      <b-form-input
                        id="rate"
                        v-model="form.locum_rate"
                        :disabled="!isVacant"
                        class="float-right"
                        name="login-email"
                        placeholder="Enter hourly rate"
                        type="number"
                      />
                    </b-input-group>
                    <small v-if="!locum_rate_p_h"
                      >Please add locum rate in setting or enter manually</small
                    >
                    <br />

                    <!--                    <b-form-input-->
                    <!--                        id="rate"-->
                    <!--                        v-model="form.locum_rate"-->
                    <!--                        class="float-right "-->
                    <!--                        name="login-email"-->
                    <!--                        placeholder="Enter hourly rate"-->

                    <!--                        type="number"-->
                    <!--                    />-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <!--                               <b-row>-->
                <!--                                   <b-col cols="12" md="6">-->
                <!--                                     <b-row>-->
                <!--                                       <b-col md="4"><b-form-checkbox-->
                <!--                                           v-model="form.hourly_rate"-->
                <!--                                           value="standard"-->
                <!--                                       >-->
                <!--                                         Standard-->
                <!--                                       </b-form-checkbox>-->
                <!--                                       </b-col>-->
                <!--                                       <b-col md="8">-->
                <!--                                         <b-form-input-->
                <!--                                             id="rate"-->
                <!--                                             v-model="form.rate"-->
                <!--                                             type="number"-->
                <!--                                             class="float-right"-->

                <!--                                             name="login-email"-->
                <!--                                         />-->
                <!--                                       </b-col>-->
                <!--                                     </b-row>-->

                <!--                                   </b-col>-->
                <!--                                   <b-col cols="12" md="3">-->
                <!--                                       <b-form-checkbox-->
                <!--                                               v-model="form.hourly_rate"-->
                <!--                                               value="enhance"-->
                <!--                                       >-->
                <!--                                           Enhanced-->
                <!--                                       </b-form-checkbox>-->
                <!--                                   </b-col>-->
                <!--                                   <b-col cols="12" md="3">-->
                <!--                                       <b-form-checkbox-->
                <!--                                               v-model="form.hourly_rate"-->
                <!--                                               value="locum"-->

                <!--                                       >-->
                <!--                                           Locum-->
                <!--                                       </b-form-checkbox>-->
                <!--                                   </b-col>-->
                <!--                               </b-row>-->
              </div>

              <div class="mt-1">
                <h5
                  class="mb-2 cursor-pointer"
                  @click="hideNoteAndShiftTypeFunction()"
                >
                  <span v-if="!hideNoteAndShiftType">+</span
                  ><span v-if="hideNoteAndShiftType">-</span> More shift options
                </h5>

                <div v-if="hideNoteAndShiftType" class="mt-1">
                  <div
                    v-if="clinicalAndNonClinicalServices.length > 0"
                    class="mt-1"
                  >
                    <b-row class="mt-2">
                      <b-col md="12">
                        <h5
                          class="mb-2 cursor-pointer"
                          @click="
                            serviceTypeHideFunction();
                            selectedServiceType = 'clinical';
                          "
                        >
                          Activity (Optional)
                        </h5>
                      </b-col>

                      <b-col md="6">
                        <b-form-checkbox
                          v-model="selectedServiceType"
                          class="float-left"
                          value="clinical"
                        >
                          Clinical
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-model="selectedServiceType"
                          class="float-right"
                          value="non-clinical"
                        >
                          Non-clinical
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-row class="">
                      <b-col md="12">
                        <b-form-group>
                          <label></label>
                          <!--                      rules="required"-->
                          <validation-provider
                            #default="{ errors }"
                            :rules="selectedServiceType ? 'required' : ''"
                            name="Service"
                          >
                            <v-select
                              v-if="selectedServiceType"
                              v-model="selectedService"
                              :options="services"
                              label="name"
                              placeholder="Select Service"
                            >
                              <span slot="no-options">
                                Please add services first
                              </span>
                            </v-select>
                            <small
                              v-if="selectedServiceType"
                              class="text-danger"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="mt-1">
                    <label>Notes (Optional)</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="form.note"
                      placeholder="Note"
                      rows="3"
                    />
                  </div>
                  <b-form-checkbox
                    v-model="form.is_covid_shift"
                    :value="true"
                    class="mt-1"
                  >
                    COVID-19 Shift
                  </b-form-checkbox>

                  <div class="mt-1">
                    <h5 class="mb-2 text-bold">Shift is visible to</h5>
                    <b-row>
                      <b-col cols="12" md="4">
                        <b-form-checkbox
                          v-model="visible_locums"
                          :value="false"
                        >
                          Salaried Staff
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="12" md="8">
                        <b-form-checkbox v-model="visible_locums" :value="true">
                          Locums
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <b-alert show variant="primary">
                  <div class="alert-body">
                    <b-row>
                      <b-col class="text-bold text-black-50" cols="4">
                        <h5>
                          Total Hours:
                          <span v-if="selectedEndTime && selectedStartTime">
                            {{ totalHours }}

                            <!--                            {{ selectedEndTime.substring(0, 2) - selectedStartTime.substring(0, 2) }}-->
                          </span>
                          <span v-else>0</span>
                        </h5>
                      </b-col>
                      <b-col class="text-bold text-black-50" cols="8"
                        ><h5>
                          Total Shift Cost:

                          <span>
                            <span
                              >£
                              {{
                                salariedStaffCost <= 0 ? 0 : salariedStaffCost
                              }}</span
                            ><span v-if="visible_locums === true"
                              >/ £{{ locumCost <= 0 ? 0 : locumCost }}</span
                            >

                            <!--                          {{totalShiftCost(selectedStartTime,selectedEndTime, 10)}}-->
                          </span>
                        </h5></b-col
                      >
                    </b-row>
                  </div>
                </b-alert>
              </div>

              <div class="flex mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="createShift()"
                >
                  Save
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="white"
                  @click="cancel(); hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BFormDatepicker,
  BFormGroup,
  BAlert,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormTextarea,
  VBToggle,
  VBModal,
  BFormInput,
  BOverlay,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import shift from "@/apis/modules/shift";
import moment from "moment";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import MomentMixin from "@/mixins/MomentMixin";
import SettingsAPI from "@/apis/modules/settings";
import settings from "@/apis/modules/settings";

extend("required", {
  message: () => "This field is required",
});

export default {
  name: "sideBar",
  components: {
    BFormDatepicker,
    BFormGroup,
    BAlert,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },
  mixins: [MomentMixin],

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const min = new Date(today);
    min.setMonth(min.getMonth());
    min.setDate(min.getDate());
    return {
      isEndDate: false,
      selecetedRoles: [],
      RoleLists: [],
      hideNoteAndShiftType: false,
      cardBckground1: "card-1",
      cardBckground2: "card-2",
      locumCost: 0,
      salariedStaffCost: 0,
      totalHours: 0,
      loading: false,
      min: min,
      config: {
        enableTime: false,
        noCalendar: false,
        dateFormat: "d-m-Y",
        locale: {
          firstDayOfWeek: 1,
        },
      },
      selectedProgramme: "",
      selectedLocation: "",
      selectedJobRole: "",
      selectedStaff: "",
      timePicker: "",
      programmesOption: [],
      locationsOption: [],
      jobRoleOption: [],
      usersOption: [],
      serviceTypeHide: false,
      breakTimeHide: false,
      selectedServiceType: "clinical",
      selectedShiftBase: "profession",
      services: [],
      selectedService: "",
      selectedShiftType: "",
      shiftTypes: [],
      selectedDate: "",
      selectedStartTime: "",
      selectedEndTime: "",
      visible_locums: false,
      isVacant: true,
      roleList: [],
      enhanced_rate_p_h: "",
      standard_rate_p_h: "",
      locum_rate_p_h: "",
      form: {
        is_visible_locums: "",
        start: "",
        end: "",
        note: "",
        break_minutes: "",
        service_id: "",
        assignee_id: "",
        is_covid_shift: false,
        shift_type_id: "",
        salary_staff_rate_type: "standard",
        salary_staff_rate: 0,
        locum_rate: 0,
        role_id: undefined,
      },
      ShiftTemplates: [],
      clinicalAndNonClinicalServices: [],
    };
  },

  model: {
    prop: "isAddNewShiftSidebarActive",
    event: "update:is-add-new-shift-sidebar-active",
  },
  props: {
    isAddNewShiftSidebarActive: {
      type: Boolean,
      required: true,
    },
    cellData: {
      type: Object,
      required: false,
    },
    selectedMoreData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedProgrammeAndLocation: function () {
      return this.selectedMoreData;
    },
    selectedCellData: function () {
      return this.cellData;
    },
  },
  watch: {
    async cellData() {
      this.data = await this.cellData;
      if (this.data) {
        this.selectedDate = await this.momentFormat(
          this.data.data.startTime,
          "YYYY-MM-DD"
        );
      }
    },
    selectedJobRole(val) {
      this.isVacant = true;
      this.filterJobRoles(val);
      this.getJobRoleList(val);
    },
    selectedStaff() {
      this.isVacant = false;
    },
    "form.salary_staff_rate_type"(val) {
      if (val == "enhanced") {
        this.form.salary_staff_rate = this.enhanced_rate_p_h
          ? this.enhanced_rate_p_h
          : 0;
      } else {
        this.form.salary_staff_rate = this.standard_rate_p_h
          ? this.standard_rate_p_h
          : 0;
      }
    },
    async isAddNewShiftSidebarActive(val) {
      if (!val) {
        await this.dataClear();
        return;
      } else {
        await this.getProgrammes();
        await this.getShiftTemplates();
        await this.getServices("clinical");
        await this.getShiftTypes();
        await this.setSelectedFilter();
        await this.getRole();
      }

      /* if (!this.is_cell_clicked){
          this.selectedRole = undefined
          this.selectedHealthcare_professional = undefined
         this.$nextTick(() => {
           this.$refs.add_shift.reset()
         })
       }*/
      this.isHaveLeave = false;
      this.leaveErrorMessage = "";
      this.selectedStartTime = "08:00:00";
    },
    async selectedProgramme(val) {
      if (val !== "") {
        await this.getLocations(val.id);
      }
    },
    selectedLocation(val) {
      if (val !== "") {
        this.getUserData(val.id);
      }
    },
    selectedServiceType() {
      if (this.selectedServiceType === "clinical") {
        this.getServices("clinical");
        this.getShiftTypes();
      } else {
        this.getServices("non-clinical");
      }
    },

    selectedStartTime() {
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime
      );
      this.salariedStaffCost = this.totalShiftCost(
        this.totalHours,
        this.form.salary_staff_rate
      );
    },
    selectedEndTime() {
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime
      );
      this.salariedStaffCost = this.totalShiftCost(
        this.totalHours,
        this.form.salary_staff_rate
      );
    },
    "form.break_minutes"() {
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime,
        this.form.break_minutes
      );
      this.salariedStaffCost = this.totalShiftCost(
        this.totalHours,
        this.form.salary_staff_rate
      );
    },

    "form.salary_staff_rate"(newValue) {
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime,
        this.form.break_minutes
      );
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, newValue);
    },
    "form.locum_rate"(newValue) {
      this.totalHours = this.timeDifferentHours(
        this.selectedStartTime,
        this.selectedEndTime,
        this.form.break_minutes
      );
      this.locumCost = this.totalShiftCost(this.totalHours, newValue);
    },
    // "selecetedRoles"(val){
    //   if(val)this.filterJobRoles(val)
    //
    // },
  },
  methods: {
    async filterJobRoles(val, id = null) {
      try {
        this.loading = true;
        let allRoles = [];
        const Response = await settings.getRolesWithoutPagination();
        allRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions,
        }));

        let selectedRoleIDS = [];
        allRoles.map((x) => {
          x.profession.map((y) => {
            val.map((k) => {
              if (k === y) selectedRoleIDS.push(x.value);
            });
          });
        });
        this.RoleLists = selectedRoleIDS.map((x) => {
          return allRoles.find((e) => e.value === x);
        });

        this.RoleLists = Array.from(
          new Set(this.RoleLists.map((a) => a.value))
        ).map((value) => {
          return this.RoleLists.find((a) => a.value === value);
        });
      } catch (e) {}
      this.loading = false;
    },
    // async getRole(){
    //   try {
    //     const Response= await settings.getRolesWithoutPagination()
    //     this.RoleLists = Response.data.data.map((x) => ({
    //       text: x.name,
    //       value: x.id,
    //       profession : x.professions,
    //     }))

    //   }catch (error){
    //     this.convertAndNotifyError(error)
    //   }

    // },
    hideNoteAndShiftTypeFunction() {
      this.hideNoteAndShiftType = !this.hideNoteAndShiftType;
    },
    changeColor(data) {
      console.log(data);
      console.log(this.ShiftTemplates);
      Object.keys(this.ShiftTemplates).forEach((key) => {
        this.ShiftTemplates[key].isSelect = false;
      });
      data.isSelect = true;
      console.log(data);
    },
    async setSelectedFilter() {
      if (sessionStorage.getItem("selected_programme_id") !== null) {
        this.selectedProgramme = this.programmesOption.filter(
          (m) =>
            m.id === parseInt(sessionStorage.getItem("selected_programme_id"))
        )[0];
        if (sessionStorage.getItem("selected_location_id") !== null) {
          this.selectedLocation = this.locationsOption.filter(
            (m) =>
              m.id === parseInt(sessionStorage.getItem("selected_location_id"))
          )[0];
        }
      } else {
      }
    },
    async shiftTemplateSet(template) {
      this.form.break_minutes = template.break_minutes;
      this.selectedStartTime = template.start_time;
      this.selectedEndTime = template.end_time;
      this.serviceTypeHide = true;
      this.selectedServiceType = template.service.type;
      this.selectedService = template.service;
      this.form.note = template.note;
      // if (template.service.type ==='clinical'){
      //
      // }else {
      //
      // }
      // this.selectedService = template.service

      try {
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    async getShiftTemplates() {
      try {
        const response = await shift.getShiftTemplateWithoutPagination();
        this.ShiftTemplates = response.data.data.map((x) => ({
          id: x.id,
          start_time: x.start_time,
          end_time: x.end_time,
          break_minutes: x.break_minutes,
          note: x.note,
          service: {
            name: x.service.name,
            id: x.service.id,
            type: x.service.type,
          },
          isSelect: false,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    async cellClicked() {
      this.is_cell_clicked = true;
      setTimeout(() => {
        this.is_cell_clicked = false;
      }, 1000);
    },
    async getUserData(id) {
      try {
        this.loading = true;
        const response = await shift.getUserFormLocation(id);
        const JobRoleResponse = await SettingsAPI.getAllJobRoles();
        // const jobRoles = response.data.data[0].job_roles[0]
        const users = response.data.data;
        const jobreoleArray = JobRoleResponse.data.data.map((x) => ({
          id: x.id,
          text: x.name,
        }));

        this.jobRoleOption = _.uniqBy(jobreoleArray, "id");

        this.usersOption = users.map((x) => ({
          id: x.user.id,
          text: x.user.first_name + " " + x.user.last_name,
          job_role_id: x.location_job_role.id,
        }));
        if (
          this.selectedCellData.data &&
          this.selectedProgrammeAndLocation.location_id &&
          this.selectedProgrammeAndLocation.location_id
        ) {
          // this.selectedJobRole = await Object(this.jobRoleOption.filter(m => m.id === this.selectedCellData.data.group_id))[0]
          // this.selectedStaff = await Object(this.usersOption.filter(m => m.id === this.selectedCellData.data.user_id))[0]
        }

        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },

    async getServices(filter) {
      try {
        this.loading = true;
        const serviceResponse = await shift.getServicesInPCN(filter);

        this.selectedService = "";
        this.services = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id,
        }));
        this.clinicalAndNonClinicalServices =
          this.clinicalAndNonClinicalServices.concat(this.services);
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getShiftTypes() {
      try {
        this.loading = true;
        const serviceResponse = await shift.getShiftTypesInPCN();

        this.shiftTypes = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id,
        }));
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getJobRoleList(id) {
      try {
        this.loading = true;
        const Response = await SettingsAPI.assignedJobRoleList();
        this.roleList = Response.data.data.map((x) => ({
          id: x.id,
          job_role_id: x.pivot.job_role_id,
          role: x.name,
          standard_rate_p_h: x.pivot.standard_rate_p_h,
          enhanced_rate_p_h: x.pivot.enhanced_rate_p_h,
          locum_rate_p_h: x.pivot.locum_rate_p_h,
        }));

        const role = [];
        this.roleList.forEach(function (x) {
          if (x.id == id) {
            role.push(x);
          }
        });

        if (role.length == 0) {
          this.form.salary_staff_rate = 0;
          this.form.locum_rate = 0;
          this.enhanced_rate_p_h = "";
          this.standard_rate_p_h = "";
          this.locum_rate_p_h = "";
        }

        this.loading = false;
        this.locum_rate_p_h = role[0].locum_rate_p_h;
        this.standard_rate_p_h = role[0].standard_rate_p_h;
        this.enhanced_rate_p_h = role[0].enhanced_rate_p_h;
        this.form.locum_rate = role[0].locum_rate_p_h
          ? role[0].locum_rate_p_h
          : 0;
        this.form.salary_staff_rate = role[0].standard_rate_p_h
          ? role[0].standard_rate_p_h
          : 0;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    serviceTypeHideFunction() {
      this.serviceTypeHide = !this.serviceTypeHide;
    },
    breakTimeHideFunction() {
      this.breakTimeHide = !this.breakTimeHide;
    },
    async getProgrammes() {
      try {
        this.loading = true;
        const response = await shift.programmes();
        this.programmesOption = response.data.data.map((x) => ({
          id: x.id,
          text: x.name,
        }));
        if (this.selectedProgrammeAndLocation.programme_id) {
          this.selectedProgramme = Object(
            this.programmesOption.filter(
              (m) => m.id === this.selectedProgrammeAndLocation.programme_id
            )
          )[0];
        }

        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getLocations(id) {
      try {
        this.loading = true;
        const response = await shift.location(id);
        this.locationsOption = response.data.data.map((x) => ({
          id: x.id,
          text: x.name,
        }));
        if (this.selectedProgrammeAndLocation.location_id) {
          this.selectedLocation = Object(
            this.locationsOption.filter(
              (m) => m.id === this.selectedProgrammeAndLocation.location_id
            )
          )[0];
        }
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async createShift() {
      // date = ''
      if (await this.$refs.createShiftForm.validate()) {
        try {
          // let roleIds = this.selecetedRoles.map((x)=>{
          //   return x.value
          // })
          this.loading = true;
          this.form.is_visible_locums = this.visible_locums;
          this.form.job_role_ids = this.selectedJobRole;
          this.form.job_role_id = "";
          (this.form.role_id = this.selecetedRoles),
            (this.form.service_id = this.selectedService.id);
          this.form.shift_type_id = this.selectedShiftType.id;
          this.form.start = moment(
            this.selectedDate + ` ` + this.selectedStartTime
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          this.form.end = moment(this.selectedDate + ` ` + this.selectedEndTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          this.form.assignee_id = this.isVacant ? "" : this.selectedStaff.id;
          this.form.p_c_n_programme_id = this.selectedProgramme.id;
          this.form.p_c_n_location_id = this.selectedLocation.id;
          const response = await shift.createVacantShift(this.form);
          this.loading = false;
          if (response.data.data.error) {
            this.showErrorMessage(
              "There is an existing shift in this time slot"
            );
          } else {
            this.showSuccessMessage("Shift Added Successfully");
            this.$emit("createShift");
          }
        } catch (error) {
          this.convertAndNotifyError(error);
          this.loading = false;
        }
      }
      this.isEndDate = true;
    },

    async cancel(){
      this.isEndDate = false;
      this.isAddNewShiftSidebarActive = false
    },
    async dataClear() {
      /* this.$nextTick(() => {
         this.$refs.add_shift.reset()
       })*/
      // this.data = null
      this.isVacant = true;
      this.selectedRole = undefined;
      this.selectedHealthcare_professional = null;
      this.selectedService = [];
      this.form.rate = "";
      this.selectedServiceType = "clinical";
      this.selectedLocation = "";
      this.selectedProgramme = "";
      this.selectedJobRole = "";
      this.selectedStaff = "";
      this.serviceTypeHide = false;
      this.totalHours = 0;
      this.form.break_minutes = "";
      this.selectedStartTime = undefined;
      this.selectedEndTime = undefined;
      this.selectedDate = "";
      this.form.locum_rate = "";
      this.visible_locums = false;
      this.form.salary_staff_rate = 0;
      this.form.salary_staff_rate_type = "standard";
      (this.enhanced_rate_p_h = ""),
        (this.standard_rate_p_h = ""),
        (this.locum_rate_p_h = ""),
        (this.form.note = "");
      this.$refs.createShiftForm.reset();
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss">
.scrolling-wrapper {
  overflow-x: auto;
}

.card-block {
  height: 40px;
  padding-top: 10px;
  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border-radius: 5px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: none;
    opacity: 0.9;
    background-image: linear-gradient(
      43deg,
      #00c49a 0%,
      #00c49a 46%,
      #00c49a 100%
    );
  }

  &:focus {
    transform: translateY(-5px);
    box-shadow: none;
    opacity: 0.9;
    background-image: linear-gradient(
      43deg,
      #00c49a 0%,
      #00c49a 46%,
      #00c49a 100%
    );
  }
}

.card-1 {
  background-color: #4158d0;
  background-image: linear-gradient(#b2b2b2 100%, #b2b2b2 100%, #b2b2b2 100%);
}

.card-2 {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #00c49a 0%,
    #00c49a 46%,
    #00c49a 100%
  );
}
</style>
