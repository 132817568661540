<template>
  <div class="chell">

    <div v-if="data.state !== 'leave' &&data.state !== 'un-availability'" class="template-wrapper" v-bind:class="{ borderBind: data.state=== 'draft' }"
         :style="{background:data.applications_count !==0 ?'#EFA12C':data.color }">
      <div class="time"  >
        {{momentFormat(data.StartTime, 'HH:mm') +' - ' }} <br>
        {{ momentFormat(data.EndTime,'HH:mm')}}
      </div>

      <div class="subject" style="margin-top: 3px;" v-if="data.Subject !== '.'&& data.state !== 'leave'">
<!--        <div>{{data.programme.name}}</div>-->
<!--        <div>{{data.location.name}}</div>-->

<!--        <div v-if="data.state=== 'draft'" class="" style="overflow: hidden; text-overflow: ellipsis; background-color: #FFFFFF; border-radius: 3px; padding: 1px ;color: #4361EE;">-->
<!--          {{data.Subject}}</div>-->
<!--        <div v-else class="" style="overflow: hidden; text-overflow: ellipsis; background-color: #FFFFFF; border-radius: 3px; padding: 1px ;color: #00C49A; word-wrap:break-word;">-->
<!--          {{data.Subject}}</div>-->
      </div>

      <div style="margin-top:4px; background-color: black; padding:2px; width: 57px;border-radius: 8px" v-if="data.applications_count !==0" class="text-white font-weight-bold font-weight-700">
        <span class="p-1"><feather-icon icon="UserIcon" class=""  /> <span>{{data.applications_count}}</span></span>
      </div>
    </div>

<!--    User Leave-->
    <div v-if="data.state=== 'leave'" class="template-wrapper" >
      <div class="float-left " >
        <svg fill="none" height="15" viewBox="0 0 12 12" width="15" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9 4H9.5C10.0304 4 10.5391 4.21071 10.9142 4.58579C11.2893 4.96086 11.5 5.46957 11.5 6C11.5 6.53043 11.2893 7.03914 10.9142 7.41421C10.5391 7.78929 10.0304 8 9.5 8H9"
              stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M1 4H9V8.5C9 9.03043 8.78929 9.53914 8.41421 9.91421C8.03914 10.2893 7.53043 10.5 7 10.5H3C2.46957 10.5 1.96086 10.2893 1.58579 9.91421C1.21071 9.53914 1 9.03043 1 8.5V4Z"
              stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <p class=" ml-2 text-dark" v-if="data.is_day">Full Day</p>
      <p  v-if="data.state=== 'leave'" class=" ml-2 text-dark" >{{momentFormat(data.StartTime, 'HH:mm') }} + - + <br> {{momentFormat(data.EndTime,'HH:mm')}}</p>
    </div>


<!--    User Un Availability-->
    <div v-if="data.state === 'un-availability'" class="template-wrapper" >
      <span class="  text-dark">User unavailable</span>
      <p  class="  text-dark" >{{momentFormat(data.StartTime, 'HH:mm') +' - '+ momentFormat(data.EndTime,'HH:mm')}}</p>
    </div>
  </div>

</template>

<script>

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import {BAlert} from 'bootstrap-vue'
export default {
  name: 'weekTemplate',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  comments:{
    BAlert,

  },
  data(){
    return{
      data:''
    }
  },

  methods:{
    getTimeString: function (value) {
      return instance.formatDate(value, { skeleton: 'hm' });
    }
  },
  mounted () {
    if (this.data.applications_count !==0){
      this.data.color ='#EFA12C'
    }
  }
}
</script>

<style scoped>
.chell{
  width: 100% !important;

}
.template-wrapper{
  padding: 10px;
  height: 100%;
  font-size: 12px;

}
.borderBind{
  border-style: dashed;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  border-color: #4361EE ;
}
/* .subject{
} */



</style>
