<template>

  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isDuplicateSingleShiftSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-duplicate-single-shift-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Duplicate Single Shift
          </h5>
          <!--        - <span v-if="data.event.Subject">{{data.event.Subject}}</span>-->
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />
        </div>
        <div class="p-2">
          <validation-observer ref="duplicateShiftForm">
            <b-row>
              <b-col md="12">
                <p>shift will be duplicated as drafts. To make them visible to staff, you will need to publish rota.
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <p class="font-weight-bold font-weight-700">Selected Shift:</p>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12">
                <label>Date</label>
                <b-form-group>
                  <flat-pickr
                      v-model="copy_date"
                      :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
                      class="form-control"
                      disabled
                      placeholder="Select date"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <label>Start Time</label>

                <b-form-group>
                  <flat-pickr
                      v-model="copy_to_start"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:ss', }"
                      class="form-control"
                      disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <label>End Time</label>

                <b-form-group>
                  <flat-pickr
                      v-model="copy_to_end"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:ss', }"
                      class="form-control"
                      disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <label>Service Clinic</label>
                <b-input-group>
                  <b-form-input
                      disabled
                      id="programme"
                      v-model="programme"
                      class="float-right"
                      name="login-email"
                      type="text"
                  />

                </b-input-group>
              </b-col>
              <b-col cols="12" md="12">
                <label>Location</label>
                <b-input-group>
                  <b-form-input
                      disabled
                      id="location"
                      v-model="location"
                      class="float-right"
                      name="login-email"
                      type="text"
                  />

                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <p class="font-weight-bold font-weight-700">Paste to:</p>
              </b-col>
            </b-row>
            <b-row>
            </b-row>
            <b-row>
              <b-col cols="12" v-if="!isVacant">
                <label>User</label>
                <validation-provider
                    #default="{ errors }"
                    name="User"
                    rules=""
                >
                <v-select
                    v-model="selected_user"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="save_staffs"
                    class="mb-1"
                    label="text"
                    placeholder="Select User"
                    :disabled="isVacant"
                />
                  <small   class="text-danger" v-if="errors[0]">Select User</small>
                </validation-provider>
              </b-col>
            </b-row>




            <b-row>
              <b-col cols="12" md="12">
                <label>Date</label>

                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      name="shift paste date"
                      rules="required"
                  >

                    <flat-pickr
                        v-model="paste_to_day"
                        :config="{ enableTime: false, noCalendar: false, minDate: new Date(), dateFormat: 'd-m-Y', mode:'multiple', locale: {firstDayOfWeek: 1}}"
                        class="form-control"
                        locale="en-UK"
                        placeholder="Select shift paste  date"
                    />
                    <small   class="text-danger" v-if="errors[0]">Select shift paste data</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="mb-1" cols="12">
                <b-form-checkbox
                    v-model="isVacant"
                    value="true"

                >
                  Vacant Shift
                </b-form-checkbox>

              </b-col>

<!--              <b-col cols="12" md="6">-->

<!--                <b-form-group>-->
<!--                  <label>Role</label>-->
<!--                  <v-select-->
<!--                      v-model="selectedRoleForEdit"-->
<!--                      :options="groups"-->
<!--                      label="text"-->
<!--                      placeholder="Select Role"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col cols="12" md="6">-->
<!--                <b-form-group v-if="!isVacant">-->
<!--                  <label>Healthcare Professional</label>-->
<!--                  <v-select-->

<!--                      v-model="selectedHealthcare_professionalForEdit"-->
<!--                      :options="users.filter(x => x.groupId === selectedRoleForEdit.id)"-->
<!--                      label="text"-->
<!--                      placeholder="Select healthcare professional"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->


            </b-row>

            <div class="flex mt-5">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="duplicateFormSubmit()"
              >
                Duplicate
              </b-button>
              <b-button
                  v-ripple.400="'rgb(179,179,179)'"
                  class="ml-2"
                  @click="isDuplicateSingleShiftSidebarActive = false"
              >
                Cancel
              </b-button>
            </div>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BButton,
  BSidebar,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  VBToggle, VBModal, BOverlay
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import MomentMixin from '@/mixins/MomentMixin'
import shift from '@/apis/modules/shift'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'


export default {
  name: 'duplicateSingleShiftSideBar',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BInputGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    flatPickr,
    BFormInput,
    BOverlay,
    ValidationObserver,
    ValidationProvider,

  },
  mixins: [MomentMixin],
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal
  },
  data () {
    return {
      selectedVacantRole:[],
      selected_user:'',
      save_staffs:[],
      required,
      isVacant: false,
      selectedHealthcare_professionalForEdit: {},
      selectedRoleForEdit: {},
      selectedRole: [],
      selectedHealthcare_professional: [],
      projectResourceDataSource: [],
      employeeDataSource: [],
      loading: false,
      selectedRange: '',
      dateOption: [],
      data: '',
      selected: '',
      timePicker: '',
      selectedMultiple: '',
      location: '',
      programme: '',
      copy_date: null,
      copy_to_start: null,
      copy_to_end: null,
      paste_to_day: [],
      paste_to_start: null,
      paste_to_end: null,
      visible_locums: '',

      formDuplicate: {
        hourly_rate: '',
        start: '',
        end: '',
        note: '',
        break_minutes: '',
        service_id: '',
        assignee_id: '',
        rate: '',
        salary_staff_rate_type: '',
        salary_staff_rate: null,
        locum_rate: null
      }

    }
  },
  model: {
    prop: 'isDuplicateSingleShiftSidebarActive',
    event: 'update:is-duplicate-single-shift-sidebar-active'
  },
  props: {
    isDuplicateSingleShiftSidebarActive: {
      type: Boolean,
      required: false
    },
    cellData: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    }
  },
  watch: {
    async cellData () {
      this.data = this.cellData.event
      await this.dataSet()
    },
    isDuplicateSingleShiftSidebarActive (val) {
      if (!val) {
        this.formDuplicate = {}
        this.paste_to_day = []
      }else {
        this.getStaffByLocation()
      }
    }
  },
  methods: {
  async getStaffByLocation() {
    try {
      this.loading = true
      const response = await shift.getUserFormLocation(this.data.location.id)
      const users = response.data.data

      const staffs = users.map((x)=>({
        text: x.user.first_name +' ' +x.user.last_name,
        id: x.user.id,
        user_id:x.user.id,
        groupId: x.location_job_role.id,
      }))
      this.save_staffs = _.uniqBy(staffs,'id')
      this.loading = false
    } catch (error) {
      this.convertAndNotifyError(error)
      this.loading = false

    }
  },
    async getAllJobRole(){
      try {
        // const Response = await SettingsAPI.getJobRoleList()
        // this.roleList =  Response.data.data.map((x) => ({
        //       id: x.id,
        //       text: x.name,
        //       job_role_id: x.id,
        //       standard_rate_p_h: '',
        //       enhanced_rate_p_h: '',
        //       locum_rate_p_h: ''
        //     })
        // )
        if (this.data.vacant_job_roles !== null){
          this.selectedVacantRole = await this.data.vacant_job_roles.map(x => x.job_role.id)
        }

      }catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async dataSet () {
      this.loading = true
      this.formDuplicate.break_minutes = this.data.break_minutes
      this.formDuplicate.rate = this.data.rate
      this.formDuplicate.note = this.data.note
      this.formDuplicate.locum_rate = this.data.locum_rate
      this.formDuplicate.salary_staff_rate = this.data.salary_staff_rate
      this.formDuplicate.salary_staff_rate_type = this.data.salary_staff_rate_type
      this.formDuplicate.service_id = this.data.service.length !== 0 ? this.data.service.id : null
      this.formDuplicate.shift_type_id = this.data.shift_type.length !== 0 ? this.data.shift_type.id : null
      this.visible_locums = !!this.data.is_visible_locums
      this.isVacant = this.data.user === null
      this.copy_date = this.momentFormat(this.data.StartTime, 'YYYY-MM-DD')
      this.copy_to_start = this.momentFormat(this.data.StartTime, 'HH:mm')
      this.copy_to_end = this.momentFormat(this.data.EndTime, 'HH:mm')
      this.location = this.data.location.name
      this.programme = this.data.programme.name
      this.projectResourceDataSource = this.groups
      if(this.data.job_role !== null){
        this.selectedRoleForEdit = Object(this.groups.filter(m => m.id === this.data.job_role.id))[0]
        this.selectedHealthcare_professionalForEdit = Object(this.users.filter(m => m.id === this.data.user_id))[0]
      }else {
        await this.getAllJobRole()

      }
      this.loading = false
    },
    async duplicateFormSubmit () {
      // if (await this.$refs.editShiftForm.validate()) {
      if (await this.$refs.duplicateShiftForm.validate()) {
        try {

          this.loading = true
          for (const value of this.paste_to_day.split(',')) {
            let index = this.paste_to_day.split(',').indexOf(value);
            this.formDuplicate.start = moment((value.trim()+' '+ this.copy_to_start),'DD-MM-YYYY HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')
            this.formDuplicate.end = moment((value.trim()+' '+ this.copy_to_end),'DD-MM-YYYY HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')
            this.formDuplicate.p_c_n_programme_id = this.data.programme.id
            this.formDuplicate.p_c_n_location_id = this.data.location.id
            if (this.isVacant) {
              this.formDuplicate.assignee_id = ''
              this.formDuplicate.job_role_id = ''
              this.formDuplicate.job_role_ids = this.selectedVacantRole
              await shift.createVacantShift(this.formDuplicate)
              } else {
                this.formDuplicate.assignee_id = this.selected_user.id
              this.formDuplicate.job_role_id = this.selected_user.groupId
              await shift.createShift(this.formDuplicate)
              }



          }


          this.showSuccessMessage('Shift Duplicated Successfully')
          this.loading = false
          this.$emit('editShift')
        }
         catch (error) {
          this.convertAndNotifyError(error)
          this.loading = false
        }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>



