<template>
  <div>
    <b-row>
      <b-col cols="12" lg="3" md="4">
        <b-form-group>
          <v-select
            v-model="filters['p_c_n_programme_id']"
            :options="programmesOption"
            :reduce="(programmesOption) => programmesOption.id"
            label="text"
            placeholder="Select Service Clinic"
          >
            <span slot="no-options"> Please create Programme First </span>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3" md="2">
        <b-form-group>
          <v-select
            v-model="filters['p_c_n_location_id']"
            :options="locationsOption"
            :reduce="(locationsOption) => locationsOption.id"
            label="text"
            placeholder="Select Location"
          >
            <span slot="no-options"> Please select Service Clinic first </span>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3" md="2">
        <b-form-group>
          <v-select
            v-model="filters['job_role_id']"
            :options="jobRoleOption"
            :reduce="(jobRoleOption) => jobRoleOption.id"
            label="text"
            placeholder="All Professions"
          >
            <span slot="no-options">
              Please select Service Clinic and Location first
            </span>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3" md="4">
        <div class="flex flex-wrap float-md-right mb-2 mb-md-0">
          <!-- <b-button
            v-b-tooltip.hover.top="'Print'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-icon mr-1 text-primary shadow-lg"
            border-colour="#1B9AAA"
            variant="lighten-2"
            size="sm"
            @click="printData()"
          >
            <feather-icon size="20" icon="PrinterIcon" />
          </b-button> -->
          <b-button
            v-b-tooltip.hover.top="'Duplicate week'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-icon mr-1 text-primary shadow-lg"
            variant="lighten-2"
            size="sm"
            @click="duplicateShift()"
          >
            <feather-icon size="20" icon="CopyIcon" />
          </b-button>
          <b-button
            v-if="draftLength === 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            disabled
            variant="dark"
            @click="publishScheduler()"
          >
            <span>All Shifts Published</span>
          </b-button>
          <!-- <b-button
            v-if="draftLength !== 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="draftLength !== 0 ? 'dark' : 'light-dark'"
            @click="publishScheduler()"
          >
            <span v-if="draftLength === 1"
              >Publish {{ draftLength }} Shift</span
            >
            <span v-if="draftLength > 1">Publish {{ draftLength }} Shift</span>
          </b-button> -->

          <b-button
            v-if="draftLength >= 1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="draftLength !== 0 ? 'dark' : 'light-dark'"
            @click="publishScheduler()"
          >
            <span>Publish {{ draftLength }} Shift</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-card
      v-if="filters.p_c_n_programme_id !== null"
      bg-variant=""
      class="full-height"
    >
      <b-row>
        <b-col class="mb-2" cols="12" md="3">
          <v-select
            v-model="selected_user"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="save_staffs.filter((m) => m.id !== -1)"
            :reduce="(save_staffs) => save_staffs.id"
            class="mb-1"
            label="text"
            multiple
            placeholder="Select User"
          />
        </b-col>
        <b-col class="" cols="12" md="9">
          <b-row class="mb-1">
            <b-col md="4">
              <v-select
                v-model="selected_role_ids"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(roleOptions) => roleOptions.id"
                class="mb-1"
                label="name"
                placeholder="Select Role"
              />
            </b-col>
            <b-col cols="4">
              <v-select
                v-model="selected_service_ids"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="serviceOption"
                :reduce="(serviceOption) => serviceOption.id"
                class="mb-1"
                label="name"
                multiple
                placeholder="Select service"
              />
            </b-col>
            <b-col md="4">
              <v-select
                v-model="selectedShiftType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[
                  { title: 'All', value: 'all' },
                  { title: 'Vacant', value: 'vacant' },
                  { title: 'Filled', value: 'filled' },
                  { title: 'Draft', value: 'draft' },
                  { title: 'Cancelled', value: 'cancelled' },
                ]"
                class="mb-1"
                label="title"
                placeholder="All Shifts"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-overlay :show="loading" rounded="sm">
        <!--        :resizeStart="dragStop"-->
        <!--        :resizeStop="dragStop"-->
        <ejs-schedule
          id="scheduler"
          ref="scheduler"
          :allowTextWrap="true"
          :cellClick="onCellClick"
          :current-view="currentView"
          :dataBinding="dataBinding"
          :dragStart="dragStart"
          :dragStop="dragStop"
          :eventClick="onEventClick"
          :eventRendered="onEventRendered"
          :eventSettings="eventSettings"
          :group="group"
          :popupOpen="openpopup"
          :quickInfoTemplates="quickInfoTemplates"
          :resource-header-template="resourceHeaderTemplate"
          :rowAutoHeight="true"
          :show-header-bar="true"
          :textWrapSettings="wrapSettings"
          :timeScale="timeScale"
          :workDays="workDays"
          cssClass="quick-info-template"
        >
          <!--                :popup-open="quickInfoTemplates"-->
          <!--                :popup-open="popUpOpen"-->

          <e-views>
            <e-view
              :eventTemplate="weekTemplate"
              displayName="DAY"
              option="TimelineDay"
            />
            <e-view
              :eventTemplate="weekTemplate"
              displayName="WEEK"
              option="TimelineWeek"
            />
            <e-view
              :eventTemplate="monthTemplate"
              displayName="MONTH"
              option="TimelineMonth"
            />
            <!--        <e-view option="TimelineWorkWeek" />-->
            <!--        <e-view option="TimelineMonth" />-->
            <!--        <e-view option="Agenda" />-->
          </e-views>
          <e-resources>
            <e-resource
              id="res"
              :allow-multiple="allowMultiple"
              :allowTextWrap="true"
              :data-source="projectResourceDataSource"
              color-field="color"
              expandedField="IsExpand"
              field="group_id"
              id-field="id"
              name="Projects"
              text-field="text"
              title="Choose Project"
            />
            <e-resource
              :allow-multiple="allowMultiple"
              :data-source="employeeDataSource"
              color-field="color"
              field="user_id"
              group-i-d-field="groupId"
              id-field="id"
              image-field="image"
              name="Employees"
              text-field="text"
              title="Employee"
            />
          </e-resources>
        </ejs-schedule>
      </b-overlay>
      <!--            <ejs-contextmenu id="contextmenu" ref="menuObj" cssClass="schedule-context-menu" target=".e-schedule" :items="menuItems" :beforeOpen="onContextMenuBeforeOpen" :select="onMenuItemSelect" ></ejs-contextmenu>-->
    </b-card>
    <b-card v-if="filters.p_c_n_programme_id === null">
      <div class="text-center p-5">
        <svg
          fill="none"
          height="250"
          viewBox="0 0 160 160"
          width="250"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z"
            fill="#F0F8FC"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M136.17 57.8711C138.52 57.8711 140.425 59.7766 140.425 62.1264C140.425 64.4762 138.52 66.3817 136.17 66.3817C133.819 66.3817 131.915 64.4762 131.915 62.1264C131.915 59.7766 133.819 57.8711 136.17 57.8711Z"
            fill="#DCEEF8"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M148.085 44.2539C151.375 44.2539 154.043 46.9211 154.043 50.2114C154.043 53.5016 151.375 56.1688 148.085 56.1688C144.795 56.1688 142.128 53.5016 142.128 50.2114C142.128 46.9211 144.795 44.2539 148.085 44.2539Z"
            fill="#DCEEF8"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M130.213 42.5547C131.153 42.5547 131.915 43.3164 131.915 44.2568C131.915 45.1972 131.153 45.9589 130.213 45.9589C129.272 45.9589 128.511 45.1972 128.511 44.2568C128.511 43.3164 129.272 42.5547 130.213 42.5547Z"
            fill="#DCEEF8"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M43.8595 40.457H116.533C120.545 40.457 123.797 43.7089 123.797 47.7209V120.394C123.797 124.406 120.545 127.658 116.533 127.658H43.8595C39.8476 127.658 36.5957 124.406 36.5957 120.394V47.7209C36.5957 43.7089 39.8476 40.457 43.8595 40.457Z"
            fill-rule="evenodd"
            stroke="#171822"
            stroke-width="3"
          />
          <path
            d="M100.426 33.1914V52.5693"
            stroke="#1B9AAA"
            stroke-linecap="round"
            stroke-width="3"
          />
          <path
            d="M36.5957 67.1016H122.877"
            stroke="#171822"
            stroke-width="3"
          />
          <path
            d="M61.668 33.1914V52.5693"
            stroke="#1B9AAA"
            stroke-linecap="round"
            stroke-width="3"
          />
          <path
            clip-rule="evenodd"
            d="M55.9746 91.3252H65.6597V81.6367H55.9746V91.3252Z"
            fill="#CCE6F4"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M55.9746 100.296C55.9746 99.3555 56.7321 98.5938 57.6767 98.5938H63.9576C64.9023 98.5938 65.6597 99.3555 65.6597 100.296V106.58C65.6597 107.521 64.9023 108.282 63.9576 108.282H57.6767C56.7321 108.282 55.9746 107.521 55.9746 106.58V100.296Z"
            fill="#1B9AAA"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M75.3533 83.3388C75.3533 82.3984 76.1107 81.6367 77.0554 81.6367H83.3362C84.2809 81.6367 85.0384 82.3984 85.0384 83.3388V89.6231C85.0384 90.5635 84.2809 91.3252 83.3362 91.3252H77.0554C76.1107 91.3252 75.3533 90.5635 75.3533 89.6231V83.3388Z"
            fill="#1B9AAA"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M75.3533 100.296C75.3533 99.3555 76.1107 98.5938 77.0554 98.5938H83.3362C84.2809 98.5938 85.0384 99.3555 85.0384 100.296V106.58C85.0384 107.521 84.2809 108.282 83.3362 108.282H77.0554C76.1107 108.282 75.3533 107.521 75.3533 106.58V100.296Z"
            fill="#1B9AAA"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M94.7319 83.3388C94.7319 82.3984 95.4979 81.6367 96.4341 81.6367H102.723C103.66 81.6367 104.426 82.3984 104.426 83.3388V89.6231C104.426 90.5635 103.66 91.3252 102.723 91.3252H96.4341C95.4979 91.3252 94.7319 90.5635 94.7319 89.6231V83.3388Z"
            fill="#1B9AAA"
            fill-rule="evenodd"
          />
        </svg>
        <br />
        <div class="mt-lg-1 text-large">Please Select the Service Clinic</div>
      </div>
    </b-card>

    <side-bar
      ref="AddShiftSideBar"
      :cell-data="selectedCell"
      :is-add-new-shift-sidebar-active.sync="isAddNewShiftSidebarActive"
      :selectedMoreData="selectedMoreData"
      @createShift="createShiftcompleted()"
    />

    <!--    //user click side bar -->
    <User-side-bar
      ref="AddShiftUserSideBar"
      :cell-data="selectedCell"
      :is-add-new-shift-user-sidebar-active.sync="
        isAddNewShiftUserSidebarActive
      "
      :selectedMoreData="selectedMoreData"
      @createShift="createUserShiftcompleted()"
    />

    <VacantMultiJobRoleSidebar
      ref="VacantMultiJobRoleSidebar"
      :is-vacant-multi-job-role-sidebar-active.sync="
        isVacantMultiJobRoleSidebarActive
      "
      :selectedMoreData="selectedMoreData"
      @createShift="vacantMultiSidebarToggle()"
      @vacantMultiSidebarToggle="vacantMultiSidebarToggle()"
    />
    <edit-side-bar
      ref="EditShiftSideBar"
      :cell-data="selectedCellEdit"
      :is-edit-shift-sidebar-active.sync="isEditShiftSidebarActive"
      @editShift="editShift()"
    />
    <duplicate-side-bar
      ref="DuplicateShiftSideBar"
      :cell-data="selectedCell"
      :is-duplicate-shift-sidebar-active.sync="isDuplicateShiftSidebarActive"
    />
    <duplicate-single-shift-side-bar
      ref="DuplicateSingleShiftSideBar"
      :cell-data="selectedCellView"
      :groups="projectResourceDataSource"
      :is-duplicate-single-shift-sidebar-active.sync="
        isDuplicateSingleShiftSidebarActive
      "
      :users="employeeDataSource"
      @editShift="editShift()"
    />
    <view-application-side-bar
      ref="ViewApplicationSideBar"
      :cell-data="selectedCellView"
      :is-view-application-sidebar-active.sync="isViewApplicationSidebarActive"
    />
  </div>
</template>

<script>
import {
  SchedulePlugin,
  Agenda,
  TimelineViews,
  TimelineMonth,
  Week,
  Resize,
  DragAndDrop,
  Print,
} from "@syncfusion/ej2-vue-schedule";
import { extend } from "@syncfusion/ej2-base";
import Vue from "vue";
import header from "./header.vue";
import quickInfoContentTemplate from "./CellView/content";
import quickInfoHeaderTemplate from "./CellView/header";
import quickInfoFooterTemplate from "./CellView/footer";
import weekTemplate from "@/views/scheduler/eventsTemplates/weekTemplate";
import monthTemplate from "@/views/scheduler/eventsTemplates/monthTemplate";
import MomentMixin from "@/mixins/MomentMixin";
import Filter from "@/mixins/FilterMixin";
import moment from "moment";

import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  VBToggle,
  BModal,
  VBModal,
  BAlert,
  BBadge,
  BButtonGroup,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SideBar from "./sideBar";
import UserSideBar from "./userCreateShift";
import VacantMultiJobRoleSidebar from "./vacantMultiJobRoleSidebar.vue";
import content from "./CellView/content";
import EditSideBar from "@/views/scheduler/CellEdit/editSideBar";
import DuplicateSideBar from "@/views/scheduler/CellEdit/duplicateSideBar";
import duplicateSingleShiftSideBar from "@/views/scheduler/CellEdit/duplicateSingleSideBar";
import ViewApplicationSideBar from "@/views/scheduler/CellEdit/viewApplicationSideBar";
import shift from "@/apis/modules/shift";
import SettingsAPI from "@/apis/modules/settings";
import ProgrammesAPI from "@/apis/modules/programmes";
import settings from "@/apis/modules/settings";
import login from "@/views/auth/login";

export default Vue.extend({
  name: "Index",
  mixins: [MomentMixin, Filter],

  components: {
    UserSideBar,
    duplicateSingleShiftSideBar,
    ViewApplicationSideBar,
    DuplicateSideBar,
    EditSideBar,
    content,
    SideBar,
    VacantMultiJobRoleSidebar,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,

    BModal,
    BAlert,
    BBadge,
    BButtonGroup,
    BOverlay,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  comments: {
    SchedulePlugin,
    extend,
  },
  data() {
    return {
      IsExpand: "",
      save_staffs: [],
      selected_user: "",
      selected_service_ids: [],
      selected_role_ids: [],
      save_shifts: [],
      selectedMoreData: {
        programme_id: "",
        location_id: "",
      },
      selectedServiceType: {
        title: "Select service type",
        value: "",
      },
      selectedShiftType: {
        title: "All Shifts",
        value: "",
      },
      selected: "",
      serviceOption: [],
      roleOptions:[],
      programmesOption: [],
      locationsOption: [],
      jobRoleOption: [],
      loading: false,
      isAddNewShiftSidebarActive: false,
      isAddNewShiftUserSidebarActive: false,
      isVacantMultiJobRoleSidebarActive: false,
      isEditShiftSidebarActive: false,
      isDuplicateShiftSidebarActive: false,
      isViewApplicationSidebarActive: false,
      popOpen: true,
      eventTemplate: false,
      filters: {
        p_c_n_programme_id: null,
        p_c_n_location_id: null,
      },

      modelOpen: false,
      sidebarOpen: false,
      selectedCell: {},
      selectedCellEdit: {},
      selectedCellView: {},
      timeScale: {
        enable: false,
        interval: 60,
        slotCount: 2,
      },
      query: {
        value: "",
        start: null,
        end: null,
      },
      wrapSettings: { wrapMode: "Content" },
      dayOfWeekValue: "1",
      workDays: [1, 2, 3, 4, 5],

      resourceHeaderTemplate() {
        return { template: header };
      },
      weekTemplate() {
        return { template: weekTemplate };
      },
      monthTemplate() {
        return { template: monthTemplate };
      },
      quickInfoTemplates: {
        header() {
          return { template: quickInfoHeaderTemplate };
        },
        content() {
          return { template: quickInfoContentTemplate };
        },
        footer() {
          return { template: quickInfoFooterTemplate };
        },
      },
      timezoneData: [
        {
          timezone: "America/New_York",
          text: "(UTC-05:00) Eastern Time",
        },
        {
          timezone: "UTC",
          text: "UTC",
        },
        {
          timezone: "Europe/Moscow",
          text: "(UTC+03:00) Moscow+00 - Moscow",
        },
        {
          timezone: "Asia/Kolkata",
          text: "(UTC+05:30) India Standard Time",
        },
        {
          timezone: "Australia/Perth",
          text: "(UTC+08:00) Western Time - Pert",
        },
      ],
      projectResourceDataSource: [],
      employeeDataSource: [],
      eventSettings: {
        dataSource: "",
        cssClass: "schedule-cell-dimension",
      },
      selectedDate: new Date(2018, 3, 4),
      currentView: "TimelineWeek",
      allowMultiple: true,
      group: {
        byGroupID: true,
        resources: ["Projects", "Employees"],
        allowGroupEdit: false,
      },
      formDragEdit: {
        service_id: "",
      },
      draftLength: "",
      unAvailabilitiesDataMap: [],
      leaveDataMap: [],
    };
  },
  provide: {
    schedule: [
      Agenda,
      TimelineViews,
      TimelineMonth,
      Week,
      Resize,
      DragAndDrop,
      Print,
    ],
  },
  watch: {
    selected_user(val) {
      this.userFilter(val);
    },
    selected_service_ids(val) {
      this.serviceFilter(val);
    },
    selected_role_ids(val) {
      this.roleFilter(val);
    },
    "query.value"(values) {
      this.getShiftData(values);
    },
    selectedShiftType(value) {
      this.getselectedShiftData(value);
    },
    "filters.p_c_n_programme_id"(val) {
      if (val !== null) {
        sessionStorage.setItem("selected_programme_id", val);
        // sessionStorage.setItem('selected_location_id' ,null )
        this.getLocations(val);
        this.getStaff(val);
      } else {
        sessionStorage.setItem("selected_programme_id", null);
        // sessionStorage.setItem('selected_location_id' ,null )
      }
    },
    async "filters.p_c_n_location_id"(val) {
      if (val !== null) {
        sessionStorage.setItem("selected_location_id", val);
        await this.getStaffByLocation(val);
        await this.getUserData(val);
      } else {
        sessionStorage.setItem("selected_location_id", null);
        if (this.filters.p_c_n_programme_id) {
          await this.getStaff(this.filters.p_c_n_programme_id);
        }
      }
    },
  },
  methods: {
    dayButtonFunction() {
      this.$refs.scheduler.changeCurrentView("TimelineDay", 0);
    },
    weekButtonFunction() {
      this.$refs.scheduler.changeCurrentView("TimelineWeek", 0);
    },
    monthButtonFunction() {
      this.$refs.scheduler.changeCurrentView("TimelineMonth", 0);
    },
    filterQueryUpdate() {
      this.getShiftData();
    },
    duplicateShift() {
      this.isDuplicateShiftSidebarActive = true;
    },
    async openpopup(agrs) {
      if (!agrs.data.Guid) {
        agrs.cancel = true;
        // this.selectedCell = agrs
        // this.createShift(true)
        this.selectedCell = await agrs;
        this.selectedMoreData.programme_id = this.filters.p_c_n_programme_id;
        this.selectedMoreData.location_id = this.filters.p_c_n_location_id;
        if (agrs.data.group_id === -1) {
          this.createShift();
        } else {
          this.createShift(true);
        }
      }
    },
    async editShift() {
      this.isEditShiftSidebarActive = false;
      this.isDuplicateSingleShiftSidebarActive = false;
      await this.getShiftData();
    },
    createShift(is_cell_clicked) {
      try {
        if (is_cell_clicked) {
          if (this.filters.p_c_n_location_id) {
            this.$refs.AddShiftUserSideBar.cellClicked();
            this.isAddNewShiftUserSidebarActive =
              !this.isAddNewShiftUserSidebarActive;
          } else {
            this.showErrorMessage("Please Select the location");
          }
        } else {
          this.$refs.AddShiftSideBar.cellClicked();
          this.isAddNewShiftSidebarActive = !this.isAddNewShiftSidebarActive;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // createShift() {
    //   this.isAddNewShiftSidebarActive = !this.isAddNewShiftSidebarActive
    // },
    async publishScheduler() {
      this.$swal({
        title: "Are you sure you want to publish rota?",
        text: "Staff whose shifts have changed will be notified.",
        imageUrl: require("@/assets/images/icons/publish.png"),
        imageWidth: 80,
        imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: "Yes, publish",
        cancelButtonText: "No, go back",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.publishData();
        }
      });
    },
    async publishData() {
      try {
        this.showSuccessMessage("Shift was Published!");
        this.loading = true;
        let arrayList = [];
        this.eventSettings.dataSource.forEach((m) => {
          if (m.state === "draft") {
            arrayList.push(m.Id);
          }
        });
        await shift.publishData({ shift_ids: arrayList });
        this.loading = false;
        await this.getShiftData();
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    onEventRendered: function (args) {
      let categoryColor = args.data.color;
      if (!args.element || !categoryColor) {
        return;
      }
      args.element.style.backgroundColor = categoryColor;
    },
    printData() {
      let scheduleObj = document.getElementById("scheduler").ej2_instances[0];
      scheduleObj.print();
    },

    onTimezoneDropDownChange(args) {
      this.$refs.ScheduleObj.ej2Instances.timezone = args.value;
    },
    dataBinding(val) {
      const start_date = moment(
        val.query.params.find((x) => x.key === "StartDate").value
      ).format("YYYY-MM-DD");
      const end_date = moment(
        val.query.params.find((x) => x.key === "EndDate").value
      ).format("YYYY-MM-DD");
      this.query.start = start_date;
      this.query.end = end_date;
      this.query.value = `${start_date}_${end_date}`;
    },
    async getStaffData() {
      try {
        // this.loading = true
        // const response = (await shift.userList()).data.data
        // const group = response.filter(x => x.job_role !== null).map((x)=>({
        //       text: x.job_role.name,
        //       id: x.job_role.id,
        //       color: '#00C49A',
        //       isGroup: true
        // }))
        // this.projectResourceDataSource = _.uniqBy(group,'id')
        // this.employeeDataSource = response.filter(x => x.job_role!== null).map((x)=>({
        //   text: x.user.first_name +' ' +x.user.last_name,
        //   id: x.user.id+'-'+x.practice.id,
        //   groupId: x.job_role.id,
        //   practice_id: x.practice.id,
        //   practice_name:x.practice.name
        //
        // }))
        // this.loading = false
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getStaffByLocation(id) {
      try {
        this.loading = true;
        const response = await shift.getUserFormLocation(id);
        const users = response.data.data;
        const group = users.map((x) => ({
          text: x.location_job_role.name,
          id: x.location_job_role.id,
          color: "#00C49A",
          isGroup: true,
        }));

        this.projectResourceDataSource = _.uniqBy(group, "id");

        // vacant shift map
        this.projectResourceDataSource.splice(0, 0, {
          text: "Vacant Shifts",
          id: -1,
          color: "#00C49A",
          isGroup: true,
        });

        const staffs = users.map((x) => ({
          text: x.user.first_name + " " + x.user.last_name,
          id: x.user.id,
          user_id: x.user.id,
          groupId: x.location_job_role.id,
        }));
        this.employeeDataSource = _.uniqBy(staffs, "id");

        this.employeeDataSource.push({
          text: "Vacant Shifts",
          id: -1,
          groupId: -1,
          image: "1",
          is_have_profile: false,
          is_locum: false,
        });
        this.save_staffs = this.employeeDataSource;
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getServices() {
      try {
        const response = await shift.getServicesWithoutPagination();
        this.serviceOption = response.data.data.map((x) => ({
          name: x.name,
          id: x.id,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    async getRoles() {
      try {
        const Response = await settings.getRolesWithoutPagination();
        this.roleOptions = Response.data.data.map((x) => ({
          name: x.name,
          id: x.id,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    async getUserLeave(practice_id) {
      try {
        const payload = {
          start: this.query.start,
          end: this.query.end,
          user_ids: this.employeeDataSource.map((x) => x.id),
          practice_id: practice_id,
        };
        if (!payload.start && !payload.end) return;

        const response = await shift.getUserLeaves(payload);
        this.leaveDataMap = response.data.data.map((x) => ({
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          group_id: x.user.job_roles[0].id,
          user_id: x.user.id,
          user: null,
          reason:
            x.sickness_type_id && x.leave_reason_id == 1
              ? x.sickness_type.name
              : x.leave_reason_id
              ? x.leave_reason.name
              : "N/A",
          reason_id: x.leave_reason_id === null ? "N/A" : x.leave_reason.id,
          sickness_id: x.sickness_type_id === null ? "N/A" : x.sickness_type.id,
          job_role: x.user.job_roles,
          Subject: "Leave",
          state: "leave",
          is_day: x.is_day,
          service: [],
        }));
      } catch (error) {
        console.log(error);
      }
    },
    async getStaff(val) {
      try {
        const Response = await ProgrammesAPI.getProgrammeStaff(val, 1, 49);
        // const response = (await shift.userList()).data.data
        const group = Response.data.data.map((x) => ({
          text: x.location_job_role.name,
          id: x.location_job_role.id,
          color: "#00C49A",
          isGroup: true,
        }));

        this.projectResourceDataSource = _.uniqBy(group, "id");

        this.projectResourceDataSource.splice(0, 0, {
          text: "Vacant Shifts",
          id: -1,
          color: "#00C49A",
          isGroup: true,
        });
        //
        const staffs = Response.data.data.map((x) => ({
          text: x.user.first_name + " " + x.user.last_name,
          id: x.user.id,
          user_id: x.user.id,
          image: "1",
          is_have_profile: !!x.photo_url,
          groupId: x.location_job_role.id,
          is_locum: false,
        }));

        this.employeeDataSource = _.uniqBy(staffs, "id");
        this.employeeDataSource.push({
          text: "Vacant Shifts",
          id: -1,
          groupId: -1,
          image: "1",
          is_have_profile: false,
          is_locum: false,
        });
        this.save_staffs = this.employeeDataSource;
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },

    async getUsersUnAvailabilities() {
      try {
        const payload = {
          start: this.query.start,
          end: this.query.end,
          user_ids: this.employeeDataSource.map((x) => x.id),
          job_role_ids: this.jobRoleOption.map((x) => x.id),
        };
        if (!payload.start && !payload.end) return;

        const response = await shift.getUsersUnAvailabilities(payload);

        this.unAvailabilitiesDataMap = response.data.data.map((x) => ({
          EndTime: new Date(x.day_unavailability_ends),
          StartTime: new Date(x.day_unavailability_start),
          starTimeForEdit: new Date(x.day_unavailability_start),
          endTimeForEdit: new Date(x.day_unavailability_ends),
          group_id: x.user.job_roles[0].id,
          user_id: x.user.id,
          user: null,
          job_role: x.user.job_roles,
          Subject: "UnAvailabilities",
          state: "un-availability",
          is_day: 0,
          service: [],
        }));
      } catch (error) {}
    },

    callenderLoadingTrue() {
      this.loading = true;
    },
    callenderLoadingFalse() {
      this.loading = false;
    },
    async getJobRoleList() {
      try {
        const Response = await SettingsAPI.getRolesList();
        this.roleList = Response.data.data.map((x) => ({
          label: x.name,
          value: x.id,
          professions: x.professions,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    filterLabel(profession) {
      try {
        let array = [];
        array = profession.map((x) => {
          return this.roleList.find((e) => e.value === x);
        });
        if (array[0]) {
          return array;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getShiftData() {
      try {
        if (!this.query.start || !this.query.end) return;
        this.loading = true;
        const response = await shift.shiftData(
          this.query.start,
          this.query.end,
          this.filterQuery,
          this.selectedServiceType.value
        );

        await this.getJobRoleList();
        let practice_id =
          response.data.data.length > 0 && response.data.data[0].practice_id
            ? response.data.data[0].practice_id
            : "";
        let sheduleDataMap1 = response.data.data.map((x) => ({
          Id: x.id,
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          user_id: x.assignee_id ? x.assignee_id : -1,
          user: x.assignee_id ? x.assignee : null,
          vacant_job_roles: x.assignee_id ? null : x.vacant_job_roles,
          group_id: x.assignee === null ? -1 : x.job_role.id,
          Subject: x.service === null ? "" : x.service.name,
          service: x.service === null ? [] : x.service,
          service_id: x.service === null ? [] : x.service.id,
          shift_type: x.shift_type === null ? [] : x.shift_type,
          state: x.state,
          job_role: x.job_role === null ? null : x.job_role,
          role_id: x.role_id ? this.filterLabel(x.role_id) : null,
          receivedSwapRequests: x.received_swap_requests
            ? x.received_swap_requests
            : null,
          break_minutes: x.break_minutes,
          applications_count: x.applications_count,
          note: x.note ? x.note : "",
          rate: x.rate ? x.rate : "",
          IsAllDay: false,
          is_covid_shift: x.is_covid_shift ? x.is_covid_shift : false,
          is_assignee_locum: x.is_assignee_locum,
          is_visible_locums: x.is_visible_locums,
          salary_staff_rate: x.salary_staff_rate,
          salary_staff_rate_type: x.salary_staff_rate_type,
          locum_rate: x.locum_rate,
          hide_location:
            this.filters["p_c_n_location_id"] === null ? x.pcn_location : false,
          location: x.pcn_location,
          programme: x.pcn_programme,
        }));
        await this.getUsersUnAvailabilities();
        await this.getUserLeave(practice_id);
        let sheduleDataMap = sheduleDataMap1
          .concat(this.unAvailabilitiesDataMap)
          .concat(this.leaveDataMap);
        let sheduleDataFinalArray = sheduleDataMap.map((level, i) => {
          switch (level.state) {
            case "draft":
              if (level.user_id !== -1) {
               level.color = ' #F7F7F7'
               level.border = "#B2B2B2"
            } else {
              level.color = ' #ECEFFD'
              level.border = "#4361EE"
            }
              // level.color ='#605d5d'
              // level.color = "#B2B2B2";
              // level.border = "black";
              //level.width = '100px'
              break;
            case "cancel":
              // level.color ='#605d5d'
              level.color = "#E5E5E5";
              level.border = "black";
              //level.width = '100px'
              break;
            case "un-availability":
              // level.color ='#605d5d'
              level.color = "#ffffff";
              break;
            case "leave":
              // level.color ='#605d5d'
              level.color = "#ffffff";
              break;
            case "published":
              if (level.user_id !== -1) {
                level.color = "#00C49A";
              } else {
                level.color = "#4361EE";
              }
              if (level.applications_count !== 0) {
                level.color = "#EFA12C";
              }
              break;
          }
          // // ...
          return level;
        });
        // const staffs = response.data.data.filter(m => m.assignee_id !== null).map((x)=>({
        //   text: x.assignee.first_name +' ' +x.assignee.last_name,
        //   id: x.assignee.id,
        //   user_id:x.assignee.id,
        //   groupId: x.job_role.id,
        // }))
        //
        // this.employeeDataSource =  _.uniqBy(staffs,'id')

        // practice_name:x.practice.name
        // this.eventSettings.dataSource =  sheduleDataMap1
        this.eventSettings = { dataSource: sheduleDataFinalArray };
        this.draftLength = sheduleDataFinalArray.filter(
          (m) => m.state === "draft"
        ).length;
        this.save_shifts = sheduleDataFinalArray;
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async filterChangesAndRemoveUser(data) {
      if (data.length === 0) {
        this.showSuccessMessage("no vacant shifts");
        const salariedStaffUsers = [];
        salariedStaffUsers.push({
          text: "",
          id: -1,
          groupId: -1,
          image: "1",
          is_have_profile: false,
          is_locum: false,
        });
        this.employeeDataSource = salariedStaffUsers;
        // this.eventSettings = {dataSource: this.save_shifts}
      } else {
        const salariedStaffUsers = data.map((x) => ({
          text:
            x.user === null ? "" : `${x.user.first_name} ${x.user.last_name}`,
          id: x.user === null ? -1 : x.user.id,
          groupId: x.user === null ? -1 : x.job_role.id,
          image: "1",
          text: "Vacant Shifts",
          is_have_profile: x.user === null ? false : x.photo_url ? true : false,
          is_locum: false,
        }));
        this.employeeDataSource = await _.uniqBy(salariedStaffUsers, "id");
        // this.eventSettings = {dataSource: this.save_shifts}
      }
    },
    userFilter(array) {
      if (array.length !== 0) {
        this.employeeDataSource = this.save_staffs.filter((m) =>
          array.includes(m.user_id)
        );
      } else {
        this.employeeDataSource = this.save_staffs;
      }
    },
    async serviceFilter(array) {
      if (array.length !== 0) {
        this.eventSettings = {
          dataSource: this.save_shifts.filter((item) =>
            array.includes(item.service_id)
          ),
        };
        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource);
      } else {
        if (
          sessionStorage.getItem("selected_programme_id") === null ||
          sessionStorage.getItem("selected_location_id") === null
        ) {
          await this.getLocations(
            parseInt(sessionStorage.getItem("selected_programme_id"))
          );
          await this.getStaff(
            parseInt(sessionStorage.getItem("selected_programme_id"))
          );
          this.filters["p_c_n_programme_id"] = this.programmesOption[0].id;
        } else {
          await this.getStaffByLocation(
            parseInt(sessionStorage.getItem("selected_location_id"))
          );
          await this.getUserData(
            parseInt(sessionStorage.getItem("selected_location_id"))
          );
        }

        this.eventSettings = { dataSource: this.save_shifts };
      }
    },
    async roleFilter(array) {
      if (array) {
         this.eventSettings = {
          dataSource: this.save_shifts.filter((item) =>{
            if(item.role_id){
             return item.role_id.find((roleId)=>{
               return roleId.value === array
              })
            }
          }),
        };
        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource);
      } else {
        if (
          sessionStorage.getItem("selected_programme_id") === null ||
          sessionStorage.getItem("selected_location_id") === null
        ) {
          await this.getLocations(
            parseInt(sessionStorage.getItem("selected_programme_id"))
          );
          await this.getStaff(
            parseInt(sessionStorage.getItem("selected_programme_id"))
          );
          this.filters["p_c_n_programme_id"] = this.programmesOption[0].id;
        } else {
          await this.getStaffByLocation(
            parseInt(sessionStorage.getItem("selected_location_id"))
          );
          await this.getUserData(
            parseInt(sessionStorage.getItem("selected_location_id"))
          );
        }

        this.eventSettings = { dataSource: this.save_shifts };
      }
    },
    async getselectedShiftData(value) {
      if (value === null) {
        if (
          sessionStorage.getItem("selected_programme_id") === null ||
          sessionStorage.getItem("selected_location_id") === null
        ) {
          await this.getLocations(
            parseInt(sessionStorage.getItem("selected_programme_id"))
          );
          await this.getStaff(
            parseInt(sessionStorage.getItem("selected_programme_id"))
          );
          this.filters["p_c_n_programme_id"] = this.programmesOption[0].id;
        } else {
          await this.getStaffByLocation(
            parseInt(sessionStorage.getItem("selected_location_id"))
          );
          await this.getUserData(
            parseInt(sessionStorage.getItem("selected_location_id"))
          );
          this.eventSettings = { dataSource: this.save_shifts };
        }
      }
      switch (value.value) {
        case "draft":
          this.eventSettings = {
            dataSource: this.save_shifts.filter((x) => x.state === "draft"),
          };
          await this.filterChangesAndRemoveUser(this.eventSettings.dataSource);

          break;
        case "vacant":
          this.eventSettings = {
            dataSource: this.save_shifts.filter(
              (x) => x.state === "published" && x.user === null
            ),
          };
          await this.filterChangesAndRemoveUser(this.eventSettings.dataSource);

          break;
        case "filled":
          this.eventSettings = {
            dataSource: this.save_shifts.filter(
              (x) => x.state === "published" && x.user !== null
            ),
          };
          await this.filterChangesAndRemoveUser(this.eventSettings.dataSource);

          break;
        case "cancelled":
          this.eventSettings = {
            dataSource: this.save_shifts.filter((x) => x.state === "cancel"),
          };
          await this.filterChangesAndRemoveUser(this.eventSettings.dataSource);
          break;
        case "all":
          if (
            sessionStorage.getItem("selected_programme_id") === null ||
            sessionStorage.getItem("selected_location_id") === null
          ) {
            await this.getLocations(
              parseInt(sessionStorage.getItem("selected_programme_id"))
            );
            await this.getStaff(
              parseInt(sessionStorage.getItem("selected_programme_id"))
            );
            this.filters["p_c_n_programme_id"] = this.programmesOption[0].id;
          } else {
            await this.getStaffByLocation(
              parseInt(sessionStorage.getItem("selected_location_id"))
            );
            await this.getUserData(
              parseInt(sessionStorage.getItem("selected_location_id"))
            );
          }
          break;
      }
    },
    getUniqueIdToCalenderPosition(x) {
      return x.assignee_id && x.practice_id
        ? x.assignee_id + "-" + x.practice_id
        : null;
    },
    createShiftcompleted() {
      this.isAddNewShiftSidebarActive = !this.isAddNewShiftSidebarActive;
      this.getShiftData();
    },
    createUserShiftcompleted() {
      this.isAddNewShiftUserSidebarActive =
        !this.isAddNewShiftUserSidebarActive;
      this.getShiftData();
    },
    vacantMultiSidebarToggle() {
      this.isVacantMultiJobRoleSidebarActive =
        !this.isVacantMultiJobRoleSidebarActive;
      this.getShiftData();
    },

    async onEventClick(agrs) {
      if (
        agrs.event.state === "leave" ||
        agrs.event.state === "un-availability"
      ) {
        agrs.cancel = true;
      } else {
        this.selectedCell = await agrs;
      }
    },
    async onCellClick(agrs) {
      try {
      } catch (e) {
        console.log(e);
      }
    },
    dragStart() {},
    async dragStop(val) {
      try {
        if (val.data.group_id === -1) {
          this.formDragEdit.is_assignee_locum = val.data.is_assignee_locum;
          this.formDragEdit.salary_staff_rate = val.data.salary_staff_rate;
          this.formDragEdit.salary_staff_rate_type =
            val.data.salary_staff_rate_type;
          this.formDragEdit.locum_rate = val.data.locum_rate;
          this.formDragEdit.is_visible_locum = val.data.is_visible_locum;
          this.formDragEdit.service_id = val.data.service.id;
          this.formDragEdit.assignee_id =
            val.data.user_id !== -1 ? val.data.user_id : null;
          this.formDragEdit.job_role_id = "";
          if (val.data.vacant_job_roles === null) {
            this.formDragEdit.job_role_ids = [val.data.job_role.id];
          } else {
            this.formDragEdit.job_role_ids = val.data.vacant_job_roles.map(
              (x) => x.job_role.id
            );
          }
          this.formDragEdit.shift_type_id = val.data.shift_type.id;
          this.formDragEdit.p_c_n_programme_id = val.data.programme.id;
          this.formDragEdit.p_c_n_location_id = val.data.location.id;
          this.formDragEdit.start = moment(
            this.momentFormat(val.data.StartTime, "YYYY-MM-DD") +
              ` ` +
              this.momentFormat(val.data.StartTime, "HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          this.formDragEdit.end = moment(
            this.momentFormat(val.data.EndTime, "YYYY-MM-DD") +
              ` ` +
              this.momentFormat(val.data.EndTime, "HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          let updateResponse = await shift.updateShift(
            val.data.Id,
            this.formDragEdit
          );
          await this.getShiftData();

          if (updateResponse.data.error) {
            this.showErrorMessage(
              "There is an existing shift in this time slot"
            );
          } else {
            this.showSuccessMessage("Shift Updated Successfully");
          }
        } else {
          if (val.data.user_id === undefined) {
            this.showErrorMessage("can't move this group field");
            await this.getShiftData();
            return;
          }
          this.formDragEdit.is_assignee_locum = val.data.is_assignee_locum;
          this.formDragEdit.salary_staff_rate = val.data.salary_staff_rate;
          this.formDragEdit.salary_staff_rate_type =
            val.data.salary_staff_rate_type;
          this.formDragEdit.locum_rate = val.data.locum_rate;
          this.formDragEdit.is_visible_locum = val.data.is_visible_locum;
          this.formDragEdit.service_id = val.data.service.id;
          this.formDragEdit.assignee_id = val.data.user_id
            ? val.data.user_id
            : null;
          this.formDragEdit.job_role_id = val.data.group_id;
          this.formDragEdit.job_role_ids = [];
          this.formDragEdit.shift_type_id = val.data.shift_type.id;
          this.formDragEdit.p_c_n_programme_id = val.data.programme.id;
          this.formDragEdit.p_c_n_location_id = val.data.location.id;
          this.formDragEdit.start = moment(
            this.momentFormat(val.data.StartTime, "YYYY-MM-DD") +
              ` ` +
              this.momentFormat(val.data.StartTime, "HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          this.formDragEdit.end = moment(
            this.momentFormat(val.data.EndTime, "YYYY-MM-DD") +
              ` ` +
              this.momentFormat(val.data.EndTime, "HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          let updateResponse = await shift.updateShift(
            val.data.Id,
            this.formDragEdit
          );
          await this.getShiftData();

          if (updateResponse.data.error) {
            this.showErrorMessage(
              "There is an existing shift in this time slot"
            );
          } else {
            this.showSuccessMessage("Shift Updated Successfully");
          }
        }

        // this.loading = false
        // this.$emit('editShift')
      } catch (error) {
        this.convertAndNotifyError(error);
        await this.getShiftData();
        this.loading = false;
      }
    },

    // filter methods

    async getProgrammes() {
      try {
        this.loading = true;
        const response = await shift.programmes();
        this.programmesOption = response.data.data.map((x) => ({
          id: x.id,
          text: x.name,
        }));
        if (this.programmesOption.length !== 0) {
          if (sessionStorage.getItem("p_c_n_programme_id") === null) {
            this.filters["p_c_n_programme_id"] = this.programmesOption[0].id;
            sessionStorage.getItem("selected_location_id") === null
          } else {
            this.filters["p_c_n_programme_id"] = parseInt(
              sessionStorage.getItem("p_c_n_programme_id")
            );
          }
        }
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getLocations(id) {
      try {
        this.loading = true;
        const response = await shift.location(id);
        this.locationsOption = response.data.data.map((x) => ({
          id: x.id,
          text: x.name,
        }));
        if (sessionStorage.getItem("selected_location_id") !== null) {
          if (
            this.locationsOption.filter(
              (x) =>
                x.id ===
                parseInt(sessionStorage.getItem("selected_location_id"))
            ).length !== 0
          ) {
            this.filters["p_c_n_location_id"] = parseInt(
              sessionStorage.getItem("selected_location_id")
            );
          } else {
            this.filters["p_c_n_location_id"] = null;
          }
        }
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
    async getUserData(id) {
      try {
        this.loading = true;
        const response = await shift.getUserFormLocation(id);
        // const jobRoles = response.data.data[0].job_roles[0]
        const users = response.data.data;
        const jobreoleArray = users.map((x) => ({
          id: x.location_job_role.id,
          text: x.location_job_role.name,
        }));

        this.jobRoleOption = _.uniqBy(jobreoleArray, "id");

        this.usersOption = users.map((x) => ({
          id: x.user.id,
          text: x.user.first_name + " " + x.user.last_name,
          job_role_id: x.location_job_role.id,
        }));

        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.getShiftData();
    await this.getProgrammes();
    await this.getServices();
    await this.getRoles()
    this.$refs.scheduler.ej2Instances.firstDayOfWeek = 1;
    await this.$root.$on("indexComponentFormEventTemplate", () => {
      this.selectedCellEdit = this.selectedCell;
      this.isEditShiftSidebarActive = true;
    });
    await this.$root.$on("indexComponentFormEventTemplateDuplicate", () => {
      this.selectedCellEdit = this.selectedCell;
      this.isDuplicateShiftSidebarActive = true;
    });
    await this.$root.$on(
      "indexComponentFormEventTemplateViewApplication",
      () => {
        this.selectedCellView = this.selectedCell;
        this.isViewApplicationSidebarActive = true;
      }
    );
    await this.$root.$on(
      "indexComponentFormEventTemplateSingleDuplicate",
      () => {
        this.selectedCellView = this.selectedCell;
        this.isDuplicateSingleShiftSidebarActive = true;
      }
    );
  },
});
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"; /*print style*/

[dir]
  .e-schedule
  .e-timeline-view
  .e-work-cells:hover:not(.e-resource-group-cells),
[dir]
  .e-schedule
  .e-timeline-month-view
  .e-work-cells:hover:not(.e-resource-group-cells) {
  //content: "➕" !important;
  //background-color: red;
  position: relative;
  &:before {
    content: "+" !important;
    color: #b2b2b2;
    font-size: 30px;
    line-height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.full-height {
  height: 100% !important;
}
.e-schedule .e-timeline-view .e-resource-group-cells {
  background-color: #cce6f4;
  height: 30px !important;
}

/*[dir] .e-schedule .e-timeline-view .e-resource-cells, [dir] .e-schedule .e-timeline-month-view .e-resource-cells {*/
/*  background-color: #a81414;*/
/*  border-color: rgba(0, 0, 0, 0.12);*/
/*  border-style: solid;*/
/*}*/
//.e-schedule .e-timeline-view .e-resource-cells.parent-node {
//  background-color: #00cfe8 !important;
//}
//
.e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day {
  color: black;
  font-weight: 500;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: black;
  font-weight: 500;
  letter-spacing: 0.4000000059604645px;
}

[dir] .e-schedule .e-schedule-toolbar .e-toolbar-items {
  background: #ffffff;
}

[dir] .e-schedule .e-schedule-toolbar .e-tbar-btn {
  background: #ffffff;
}

//
//[dir] .e-schedule .e-timeline-view .e-appointment, [dir] .e-schedule .e-timeline-month-view .e-appointment {
//  border-radius: 7px;
//}

[dir] .btn.btn-icon {
  padding: 0.715rem 0.736rem;
  background-color: white;
}

[dir] .e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: white;
}

//.e-event-popup {
//  z-index: 999999 !important;
//}

[dir="ltr"] .vs--single .vs__dropdown-toggle {
  background-color: white;
}

.e-schedule .e-timeline-view .e-resource-tree-icon {
  float: right;
  padding-bottom: 10px;
  padding-top: 10px;
}

.employee-name {
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.e-resource-column {
  table-layout: fixed !important;
  width: 15% !important;
}

//ll

#scheduler {
  min-height: 660px;
}

@media (#scheduler min-height: 400px) and (#scheduler max-height: 600px) {
  .e-quick-popup-wrapper {
    bottom: 0px !important;
    top: auto !important;
  }
}

e-quick-popup-wrapper .e-event-popup .e-popup-content {
  padding: 8px 18px 2px 26px;
}

.e-quick-popup-wrapper {
  color: rgba(0, 0, 0, 0.87);
  max-width: 75px;
  min-width: 320px;
  max-height: 75px;
  min-height: 310px;
  opacity: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.e-schedule .e-timeline-view .e-appointment,
.e-schedule .e-timeline-month-view .e-appointment {
  color: #fff;
  overflow: hidden;
  position: absolute;
  height: 75px;
  border-radius: 4px;
  margin-top: 0px;
}

.e-schedule .e-timeline-view .e-appointment .e-appointment-details {
  padding: 0px !important;
}

[dir] .e-schedule .e-timeline-view .e-child-node,
[dir] .e-schedule .e-timeline-month-view .e-child-node {
  white-space: pre-wrap;
}

.employee-image {
  background-color: rgba(27, 154, 170, 0.12) !important;
  margin: 5px;
  padding: 5px;
}

.e-today {
  display: none !important; // Hide today
}

.e-agenda-item {
  color: #ffffff;
}

// td.e-header-cells {
//   display: none;
// }
</style>

