import api from '../axios';
const resource = '/api/pcn/dashboard/';


export default {
  getSummery: () => api.get(`${resource}summery`),
  getShiftApplications: () => api.get(`${resource}shift-applications`),
  getSwapApplications: () => api.get(`${resource}swap-applications`),
  acceptApplicationISwap: (id) => api.post(`${resource}swap-applications/${id}/accept`),
  rejectApplicationSwap: (id) => api.post(`${resource}swap-applications/${id}/rejected`),
  acceptApplicationInvitation: (id) => api.post(`${resource}shift_applications/${id}/accept`),
  rejectApplicationInvitation: (id, user_id) => api.post(`${resource}shift_applications/${id}/reject/${user_id}`),
  getConnectionRequests: (filterQuery) => api.get(`${resource}requests?${filterQuery}`),
  connectionRequestAccept: (id) => api.post(`${resource}requests/${id}/accept`),
  connectionRequestReject: (id) => api.post(`${resource}requests/${id}/decline`),
  updateDashboard : (payload) => api.post(`${resource}update-dashboard`,payload),
  getUpdateDashboardDetails: () =>api.get(`${resource}get-dashboard-details`),
  getTimeSheets: () =>  api.get(`${resource}time_sheets/users`)
};
