import api from '../axios';
import config from '@/constants/config';
const resource = '/api/pcn/';

export default {
  // shift for calendar
  userList: (filterQuery) => api.get(`${resource}rota/staff?`),
  shiftData: (start, end, filterQuery, service_type = null) =>
    api.get(
      `${resource}shifts?start=${start}&end=${end}&${filterQuery}&service_type=${service_type}`
    ),
  getUserLeaves: (payload) => api.post(`${resource}shifts/leaves`, payload),
  programmes: () => api.get(`${resource}rota/programmes`),

  location: (id) => api.get(`${resource}rota/programmes/${id}/locations`),

  getServicesInPCN: (filter) =>
    api.get(`${resource}rota/services?filter[type]=${filter}`),

  getServicesWithoutPagination: () =>
    api.get(`${resource}services/without-pagination`),

  getShiftTypesInPCN: (filter) => api.get(`${resource}rota/shift-types?`),

  getUserFormLocation: (location_id) =>
    api.get(`${resource}rota/locations/${location_id}/staff?`),

  createShift: (payload) => api.post(`${resource}shifts`, payload),
  createVacantShift: (payload) =>
    api.post(`${resource}shifts/vacant-store`, payload),

  createVacantMultiJobRioleShift: (payload) =>
    api.post(`${resource}multiple/vacant-shifts/jobroles`, payload),

  updateShift: (shiftId, payload) =>
    api.put(`${resource}shifts/${shiftId}`, payload),

  publishData: (payload) => api.post(`${resource}shifts/publish`, payload),

  deleteEvent: (shiftId) => api.delete(`${resource}shifts/${shiftId}`),

  duplicateMultipleDays: (payload) =>
    api.post(`${resource}shifts/duplicate`, payload),

  getShiftCount: (start, end, payload) =>
    api.post(`${resource}shifts/count?start=${start}&end=${end}`, payload),

  getApplicationsForVacantShifts: (shift_id) =>
    api.get(`${resource}shift_applications?filter[shift_id]=${shift_id}`),

  acceptApplicationInvitation: (id) =>
    api.post(`${resource}shift_applications/${id}/accept`),

  rejectApplicationInvitation: (id, user_id) =>
    api.post(`${resource}shift_applications/${id}/reject/${user_id}`),

  getShiftTemplateWithoutPagination: () =>
    api.get(`${resource}shift_template/without-pagination`),

  getUsersUnAvailabilities: (payload) =>
    api.post(`/api/pcn/shift/un-availabilities`, payload),

  //to be deleted

  // locumList: (filterQuery) => api.get(`${resource}users/locums?${filterQuery}`),
  // getCalenderData: (start, end, service_type = null) =>
  //   api.get(
  //     `/api/mp/shift?start=${start}&end=${end}&service_type=${service_type}`
  //   ),
  // getUserLeaves: (payload) => api.post(`/api/mp/shift/leaves`, payload),
  // getUsersUnAvailabilities: (payload) =>
  //   api.post(`/api/mp/shift/un-availabilities`, payload),
  //
  // getServicesInPracticeWithoutFilter: () => api.get(`/api/mp/services`),
  //
  // //salary staff API
  // myShift: () => api.get('api/mp/my-shifts'),
  //
  // // getSwapShift:(role_id) => api.get(`api/mp/salaried-staff/shifts/all?filter[job_role_id]=${role_id}`),
  // swapShift: (payload) =>
  //   api.post(`api/mp/salaried-staff/shifts/swap`, payload),
  // getSwapRequestList: () => api.get(`api/mp/salaried-staff/shifts/swap`),
  // swapRequestAccept: (id) =>
  //   api.post(`api/mp/salaried-staff/shifts/swap/${id}/accept`),
  // swapRequestReject: (id) =>
  //   api.post(`api/mp/salaried-staff/shifts/swap/${id}/reject`),
  // getSwapShift: (shift_id, role_id) =>
  //   api.get(
  //     `api/mp/salaried-staff/shifts/${shift_id}/swappable?filter[job_role_id]=${role_id}`
  //   ),
  // getApplicationListVacantShifts: (filterQuery) =>
  //   api.get(
  //     `api/mp/salaried-staff/shifts/applications/vacant-shifts?${filterQuery}`
  //   ),
  // applyVacantShift: (id) =>
  //   api.post(`api/mp/salaried-staff/shifts/applications/${id}/apply`),
};
