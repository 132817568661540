import api from '../axios';
const resource = '/api/pcn';

export default {
  getServices: (page, paginate) =>
    api.get(`${resource}/services?&page=${page}&paginate=${paginate}`),
  getServicesToAdd: (page, paginate, filterQuery) =>
    api.get(
      `${resource}/services/non-assigned?page=${page}&paginate=${paginate}&${filterQuery}`
    ),
  updateService: (payload) => api.post(`${resource}/services/sync`, payload),
  removeService: (id) => api.delete(`${resource}/services/${id}`),
  jobRoleNotAssignerList: (filterQuery) =>
    api.get(`${resource}/job-roles/not-assigned?${filterQuery}`),
  updateRoles: (payload) => api.post(`${resource}/job-roles/sync`, payload),
  assignedJobRoleList: () => api.get(`${resource}/job-roles/assigned`),
  getAllJobRoles: () => api.get(`api/job-roles`),
  deleteJobRole: (id) => api.delete(`${resource}/job-roles/${id}`),
  getUsers: (page, paginate) =>
    api.get(`${resource}/users?&page=${page}&paginate=${paginate}`),
  getAdmins: (page, paginate) =>
    api.get(`${resource}/users/admins?&page=${page}&paginate=${paginate}`),
  removeAdmin: (id) => api.get(`${resource}/users/admins/${id}/remove`),

  //get Service without pagination
  getServicesInPCN: () => api.get(`${resource}/rota/services`),

  //shift template
  getShiftTemplate: (page, paginate) =>
    api.get(`${resource}/shift_template?page=${page}&paginate=${paginate}`),
  createShiftTemplate: (payload) =>
    api.post(`${resource}/shift_template`, payload),
  removeShiftTemplate: (id) =>
    api.delete(`${resource}/shift_template/${id}/delete`),
  editShiftTemplate: (id, payload) =>
    api.put(`${resource}/shift_template/${id}/update`, payload),

  //admin invitations
  sendInvitation: (payload) => api.post(`${resource}/pcn-invitations`, payload),
  invitationList: () => api.get(`${resource}/pcn-invitations?`),
  resendInvitation: (id) =>
    api.post(`${resource}/pcn-invitations/${id}/resend`),

  changeStateCancelAllow: () =>
    api.post(`${resource}/allow_shift_cancellation?`),
  getPracticeShiftCancelState: () =>
    api.get(`${resource}/allow_shift_cancellation_state?`),

  changeStateVacant: () => api.post(`${resource}/auto_approval_shifts?`),
  getPracticeShiftVacant: () => api.get(`${resource}/auto_approval_shifts?`),

  changeStateAutoSwapVacant: () => api.post(`${resource}/auto_swap_shifts?`),
  getPracticeShiftVacantAutoSwap: () =>
    api.get(`${resource}/auto_swap_shifts?`),

  //role
  createRole: (payload) => api.post(`${resource}/roles`, payload),
  getRoles: (page, paginate) =>
    api.get(`${resource}/roles?page=${page}&paginate=${paginate}`),
  getRolesList: () => api.get(`${resource}/roles?`),
  editJob: (id, payload) => api.put(`${resource}/roles/${id}/update`, payload),
  getRolesWithoutPagination: () => api.get(`${resource}/roles`),
};
